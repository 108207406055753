/* istanbul ignore file */

import { ReactNode, useCallback, useEffect, useRef } from 'react';
import { HStack } from '@cbhq/cds-web/layout';
const outerContainerStyle = "of79324";
const outerContainerCompactStyle = "o1qh0jwa";
const innerContainerStyle = "irrckp6";
type InfiniteScrollingCarouselProps = {
  children: ReactNode[] | ReactNode;
  uniqueId?: string;
  compact?: boolean;
};
export function InfiniteScrollingCarousel({
  children,
  uniqueId = 'CQCQCQCQ',
  compact = false
}: InfiniteScrollingCarouselProps) {
  const mainRef = useRef<HTMLDivElement>(null);
  const primaryWidthRef = useRef<HTMLDivElement>(null);
  let childArray: ReactNode[];
  if (!Array.isArray(children)) {
    childArray = [children];
  } else {
    childArray = children;
  }
  const onWheel = useCallback(() => {
    if (mainRef.current && primaryWidthRef.current) {
      if (mainRef.current.scrollLeft > primaryWidthRef.current.clientWidth) {
        mainRef.current.scrollLeft %= primaryWidthRef.current.clientWidth;
      }
    }
  }, []);
  const infiniteScroll = useCallback(() => {
    if (mainRef.current) {
      mainRef.current.scrollLeft += 1;
      if (mainRef.current && primaryWidthRef.current) {
        if (mainRef.current.scrollLeft > primaryWidthRef.current.clientWidth) {
          mainRef.current.scrollLeft %= primaryWidthRef.current.clientWidth;
        }
      }
    }
  }, []);
  useEffect(() => {
    setInterval(infiniteScroll, 25);
  });
  return <div onWheel={onWheel} className={compact ? outerContainerCompactStyle : outerContainerStyle} ref={mainRef}>
      {/* cannot be a box */}
      <div className={innerContainerStyle}>
        <HStack gap={4} testID="infinite-scroll-carousel">
          <HStack gap={4} ref={primaryWidthRef} key={`ocs-infinite-scroll-carousel-first-child-|-uid-${uniqueId}`}>
            {childArray}
          </HStack>
          <HStack gap={4}>{children}</HStack>
          <HStack gap={4}>{children}</HStack>
        </HStack>
      </div>
    </div>;
}

require("./InfiniteScrollingCarousel.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./InfiniteScrollingCarousel.tsx");