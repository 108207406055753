import { useCallback, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { SpinOptionTypeEnum, SpinTheWheelState, useExecuteSpin, useGetSpinWheelState } from 'cb-wallet-data/hooks/Gamification/useSpinWheel';
import { useGetUserState, useHasCoinbaseProfile } from 'cb-wallet-data/hooks/Gamification/useUserOCSProfile';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Button } from 'wallet-cds-web/components/Button/Button';
import { Box, HStack, Spacer, VStack } from '@cbhq/cds-web/layout';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';
import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';
import { determinePrizeImage, getIndexWithId } from ':dapp/utils/spinStateUtils';

/* istanbul ignore next */
const Wheel = dynamic(async () => import('react-custom-roulette').then(mod => mod.Wheel), {
  ssr: false
});
const messages = defineMessages({
  spinTheWheel: {
    defaultMessage: 'Spin the wheel',
    description: 'Spin the wheel Button Label'
  },
  earnPoints: {
    defaultMessage: 'Earn points',
    description: 'CTA that takes users to challenges to earn more points.'
  },
  exploreExperiences: {
    defaultMessage: 'Explore experiences',
    description: 'CTA that takes users to experiences'
  },
  eligibleSubheading: {
    defaultMessage: 'Spin the wheel once a day using Coinbase Wallet.',
    description: 'sping the wheel message for eligible users'
  },
  alreadySpunHeading: {
    defaultMessage: 'You already spun the wheel today!',
    description: 'heading for users that have already spun the wheel'
  },
  alreadySpunSubHeading: {
    defaultMessage: 'Come back tomorrow for your daily free spin, only with Coinbase Wallet! Wheel resets each day at midnight PST.',
    description: 'subheading for users that have already spun the wheel'
  },
  pointsEarned: {
    defaultMessage: 'You earned {points} points',
    description: 'text for already button showing how many points you won previously'
  },
  youWonHeading: {
    defaultMessage: 'Hooray, you earned {points} points!',
    description: 'heading for users that have won'
  },
  youWonSubHeading: {
    defaultMessage: 'Congrats! You will see the points in your Summer Pass soon. Come back tomorrow for your daily free spin, only with Coinbase Wallet! Wheel resets each day at midnight PST.',
    description: 'subheading for users that won'
  },
  usdcEarned: {
    defaultMessage: 'You earned {usdcValue} USDC',
    description: 'text for already button showing how much usdc you won previously'
  },
  youWonUSDCHeading: {
    defaultMessage: 'Hooray, you earned {usdcValue} USDC!',
    description: 'heading for users that have won USDC'
  },
  youWonUSDCSubHeading: {
    defaultMessage: 'Congrats! The USDC will be deposited to your account shortly. Come back tomorrow for your daily free spin, only with Coinbase Wallet! Wheel resets each day at midnight PST.',
    description: 'subheading for users that won USDC'
  },
  switchWalletSubHeading: {
    defaultMessage: 'You can only spin the wheel using Coinbase Wallet. Switch your wallet to continue.',
    description: 'message for users using spin wheel without a coinbase wallet'
  },
  doesNotMeetLevelRequirement: {
    defaultMessage: 'You need to reach Level 1 to spin the wheel. Keep playing to earn more points!',
    description: 'message for users that do not meet the point requirement'
  },
  swithWalletButton: {
    defaultMessage: 'Switch to Coinbase Wallet',
    description: 'call to action for non cb users to switch wallets'
  },
  toastTitle: {
    defaultMessage: 'You earned {points} {type}! 🎉',
    description: 'toast message'
  }
});
const wheelBackgroundColor = ['#0052FF'];
const wheelTextColor = ['#F3F3F3'];
const stopper = {
  src: '/ocs/Stopper.svg'
};
type OCSSpinWheelProp = {
  testing?: boolean;
};
const overrideCSSWrapper = "o28h3ld";
export function OCSSpinWheel({
  testing = false
}: OCSSpinWheelProp) {
  const {
    formatMessage
  } = useIntl();
  const queryClient = useQueryClient();
  const [hasSpun, setHasSpun] = useState<boolean>(false);
  const [prizeNumber, setPrizeNumber] = useState<number>(0);
  const [prizeAmount, setPrizeAmount] = useState<number | undefined>();
  const [prizeType, setPrizeType] = useState<SpinOptionTypeEnum | undefined>();
  const [stoppedSpinning, setStoppedSpinning] = useState<boolean>(false);
  const isUsdcEnabled = useIsFeatureEnabled('ocs_spin_the_wheel_usdc');
  const toast = useToast();
  const router = useRouter();
  const {
    account
  } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS
  });
  const hasCBProfile = useHasCoinbaseProfile(account);
  const {
    setGamificationApiIssue
  } = usePageOutageContext();
  const {
    spinState
  } = useGetSpinWheelState(account?.primaryAddress, setGamificationApiIssue);
  const {
    userState
  } = useGetUserState(account?.primaryAddress);
  const {
    mutateAsync: executeSpin,
    isLoading: isLoadingSpin
  } = useExecuteSpin(setGamificationApiIssue);
  const handleStopSpin = useCallback(() => {
    /* istanbul ignore next @preserve */
    queryClient.invalidateQueries({
      queryKey: ['game_userProfileState']
    });
    setStoppedSpinning(true);
    toast.show(formatMessage(messages.toastTitle, {
      points: prizeAmount,
      type: prizeType?.toLowerCase()
    }));
  }, [queryClient, toast, formatMessage, prizeAmount, prizeType]);
  const spinWheelData = useMemo(() => spinState?.spinOptions?.map(spinOption => ({
    image: determinePrizeImage(spinOption),
    option: spinOption?.type === SpinOptionTypeEnum?.POINTS ? `${spinOption?.points} PTS` : undefined,
    style: {
      backgroundColor: !isUsdcEnabled && spinOption?.points === 1000 || spinOption?.type === SpinOptionTypeEnum?.USDC ? '#FEE002' : '#0052FF',
      textColor: !isUsdcEnabled && spinOption?.points === 1000 || spinOption?.type === SpinOptionTypeEnum?.USDC ? '#000000' : '#FFFFFF'
    }
  })), [isUsdcEnabled, spinState]);
  const handleSpin = useCallback(async () => {
    if (!hasSpun && account?.primaryAddress) {
      try {
        const res: SpinTheWheelState = await executeSpin({
          walletAddress: account?.primaryAddress
        });
        const prizeId = res?.spinData?.lastSpinResult?.id;
        if (!prizeId) {
          return;
        }
        const prizeIndex = getIndexWithId(prizeId, spinState?.spinOptions);
        setHasSpun(true);
        setPrizeAmount(res?.spinData?.lastSpinResult?.points);
        setPrizeNumber(prizeIndex);
        setPrizeType(res?.spinData?.lastSpinResult?.type);
        if (testing) {
          handleStopSpin();
        }
      } catch (err) {
        cbReportError({
          error: coerceError(err, 'error thrown when spinning wheel'),
          context: 'dapp-ocs-error',
          severity: 'error',
          isHandled: false
        });
      }
    }
  }, [setPrizeNumber, setHasSpun, hasSpun, executeSpin, account, testing, handleStopSpin, spinState?.spinOptions]);

  /* istanbul ignore next */
  const handleExploreClick = useCallback(() => {
    router.push('/ocs/play');
  }, [router]);
  const spinWheelState = useMemo(() => {
    if (!hasCBProfile) {
      return <>
          <Spacer vertical={2} />
          <TextBody as="p" color="foregroundMuted" align="center">
            {formatMessage(messages.switchWalletSubHeading)}
          </TextBody>
          <Spacer vertical={2} />

          <ThemeProvider spectrum="light">
            <HStack justifyContent="center" width="100%">
              <Button href="https://www.coinbase.com/wallet" variant="secondary" loading={isLoadingSpin} disabled={hasSpun} compact block>
                {formatMessage(messages.swithWalletButton)}
              </Button>
            </HStack>
          </ThemeProvider>
        </>;
    }

    // cannot spin due to not enough points
    if (userState?.scoreData?.currentScore && userState?.scoreData?.currentScore < 250) {
      return <>
          <TextBody as="p" color="foregroundMuted" align="center">
            {formatMessage(messages.doesNotMeetLevelRequirement)}
          </TextBody>
          <Spacer vertical={5} />
          <ThemeProvider spectrum="light">
            <HStack justifyContent="center" width="100%">
              <Button variant="secondary" onPress={handleExploreClick} block>
                {formatMessage(messages.earnPoints)}
              </Button>
            </HStack>
          </ThemeProvider>
        </>;
    }

    // able to spin
    if (spinState?.spinData.hasAvailableSpin && (!hasSpun || !stoppedSpinning)) {
      return <>
          <Spacer vertical={2} />
          <TextBody as="p" color="foregroundMuted" align="center">
            {formatMessage(messages.eligibleSubheading)}
          </TextBody>
          <Spacer vertical={2} />

          <ThemeProvider spectrum="light">
            <HStack justifyContent="center" width="100%">
              <Button testID="spinWheelButton" onPress={handleSpin} variant="secondary" loading={isLoadingSpin} disabled={hasSpun} block>
                {formatMessage(messages.spinTheWheel)}
              </Button>
            </HStack>
          </ThemeProvider>
        </>;
    }

    // already spun
    if (!spinState?.spinData.hasAvailableSpin && !hasSpun) {
      return <>
          <Spacer vertical={2} />
          <TextTitle3 as="p" color="foreground" align="center">
            {formatMessage(messages.alreadySpunHeading)}
          </TextTitle3>
          <Spacer vertical={1} />
          <TextBody as="p" align="center" color="foregroundMuted">
            {formatMessage(messages.alreadySpunSubHeading)}
          </TextBody>
          <Spacer vertical={2} />

          <ThemeProvider spectrum="light">
            <HStack justifyContent="center" width="100%">
              <Button variant="secondary" onPress={handleExploreClick} block>
                {formatMessage(messages.exploreExperiences)}
              </Button>
            </HStack>
          </ThemeProvider>
        </>;
    }

    // user has spun and stopped spinning
    if (prizeNumber !== undefined && stoppedSpinning) {
      return <>
          <Spacer vertical={2} />
          <TextTitle3 as="p" color="foreground" testID="youWon" align="center">
            {prizeType === SpinOptionTypeEnum?.POINTS ? formatMessage(messages.youWonHeading, {
            points: prizeAmount
          }) : formatMessage(messages.youWonUSDCHeading, {
            usdcValue: prizeAmount
          })}
          </TextTitle3>
          <Spacer vertical={2} />
          <TextBody align="center" as="p" color="foregroundMuted">
            {prizeType === SpinOptionTypeEnum?.POINTS ? formatMessage(messages.youWonSubHeading) : formatMessage(messages.youWonUSDCSubHeading)}
          </TextBody>
          <Spacer vertical={2} />

          <ThemeProvider spectrum="light">
            <HStack justifyContent="center" width="100%">
              <Button variant="secondary" onPress={handleExploreClick} block>
                {formatMessage(messages.exploreExperiences)}
              </Button>
            </HStack>
          </ThemeProvider>
        </>;
    }
  }, [formatMessage, handleExploreClick, handleSpin, hasCBProfile, hasSpun, isLoadingSpin, prizeAmount, prizeNumber, prizeType, spinState?.spinData.hasAvailableSpin, stoppedSpinning, userState?.scoreData?.currentScore]);
  if (!spinWheelData || hasCBProfile === undefined) {
    return null;
  }
  return <VStack alignItems="center" testID="spinWheelComponent" height="100%" width="100%">
      <Box className={overrideCSSWrapper} spacingBottom={2}>
        <Wheel onStopSpinning={handleStopSpin} mustStartSpinning={hasSpun} prizeNumber={prizeNumber} data={spinWheelData} backgroundColors={wheelBackgroundColor} textColors={wheelTextColor} outerBorderColor="#FFFFFF" radiusLineColor="#FFFFFF" radiusLineWidth={3} innerRadius={0} fontFamily="CoinbaseMono" fontSize={16} pointerProps={stopper} spinDuration={0.5} disableInitialAnimation />
      </Box>
      <VStack spacingBottom={2}>{spinWheelState}</VStack>
    </VStack>;
}

require("./OCSSpinWheel.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./OCSSpinWheel.tsx");