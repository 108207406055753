import { useNftAvatarMetadata } from 'cb-wallet-data/stores/Collection/hooks/useNftAvatarMetadata';
import { PublicProfile } from 'cb-wallet-data/stores/DecentralizedID/types';
import { useDefaultAvatarIdxByAddress } from 'cb-wallet-data/stores/WalletGroups/hooks/useDefaultAvatarIdxByAddress';
import { CollectibleMedia } from 'wallet-cds-web/components/CollectibleMedia';
import { useProfileDefaultAvatar } from 'wallet-cds-web/components/ProfileAvatar/DefaultAvatar';
import { Shape } from '@cbhq/cds-common';
import { Box } from '@cbhq/cds-web/layout';
import { RemoteImage } from '@cbhq/cds-web/media';

type GetAvatarProps = {
  publicProfile: PublicProfile;
  width?: number;
  height?: number;
  shape?: Shape;
};

export const testID = 'OCSAvatar';

export function OCSAvatar({
  publicProfile,
  width = 100,
  height = 100,
  shape = 'squircle',
}: GetAvatarProps) {
  const avatarSource =
    publicProfile?.primaryDomainType === 'eth'
      ? publicProfile?.ensDomainProfile?.profileTextRecords.avatar
      : publicProfile?.subdomainProfile?.profileTextRecords.avatar;

  const nftAvatarMetadata = useNftAvatarMetadata(avatarSource);
  const avatarIdx = useDefaultAvatarIdxByAddress(publicProfile?.address);
  const defaultAvatar = useProfileDefaultAvatar(avatarIdx);

  return (
    <Box
      borderRadius="roundedLarge"
      overflow="hidden"
      height={width}
      width={height}
      testID={testID}
    >
      {nftAvatarMetadata ? (
        <CollectibleMedia
          chainId={nftAvatarMetadata.chainId}
          width={`${width}px`}
          height={`${height}px`}
          {...nftAvatarMetadata.collectible}
        />
      ) : (
        <RemoteImage
          alt="thumbnail"
          source={defaultAvatar}
          shape={shape}
          resizeMode="cover"
          width="100%"
          height="100%"
        />
      )}
    </Box>
  );
}
