import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import Link from 'next/link';
import { Button } from 'wallet-cds-web/components/Button';
import { ColorScheme /* ResponsivePropsDevices, ResponsiveStyles */ } from '@cbhq/cds-common';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { RemoteImage } from '@cbhq/cds-web/media';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { Tag } from '@cbhq/cds-web/tag/Tag';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

export const testID = 'ocs-commuity-avatar';

const messages = defineMessages({
  link: {
    defaultMessage: 'Learn more',
    description: 'CTA for learn more button',
  },
});

const TYPE_TAG_COLOR_MAP: { [k in CommunityAvatarProps['type']]: ColorScheme } = {
  artists: 'green',
  memes: 'red',
  games: 'yellow',
  musicians: 'purple',
};

export type CommunityAvatarProps = {
  name: string;
  text: MessageDescriptor;
  url: string;
  image: string;
  type: 'artists' | 'memes' | 'games' | 'musicians';
};

export function CommunityAvatar({ name, image, text, url, type }: CommunityAvatarProps) {
  const { formatMessage } = useIntl();

  return (
    <Box testID={testID} alignItems="center">
      <Box borderRadius="roundedLarge" overflow="hidden" minHeight={170} minWidth={170}>
        <RemoteImage
          alt={`${name} Thumbnail`}
          source={image}
          shape="squircle"
          resizeMode="contain"
          height={170}
          width={170}
        />
      </Box>
      <VStack gap={1} spacingStart={4}>
        <Tag colorScheme={TYPE_TAG_COLOR_MAP[type]} intent="promotional" alignSelf="flex-start">
          <ThemeProvider scale="xSmall">
            <TextBody as="span" transform="uppercase" mono color="currentColor">
              {type}
            </TextBody>
          </ThemeProvider>
        </Tag>

        <ThemeProvider scale="xxLarge">
          <TextHeadline as="h3">{name}</TextHeadline>
        </ThemeProvider>
        <TextBody as="p" color="foregroundMuted" spacingBottom={1}>
          {formatMessage(text)}
        </TextBody>

        <Box width="fit-content">
          <Link href={url} passHref>
            <Button variant="secondary" block compact>
              {formatMessage(messages.link)}
            </Button>
          </Link>
        </Box>
      </VStack>
    </Box>
  );
}
