import { useCallback, useMemo, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useGetUserState } from 'cb-wallet-data/hooks/Gamification/useUserOCSProfile';
import { toJpeg } from 'html-to-image';
import { Button } from 'wallet-cds-web/components/Button';
import { useToggler } from '@cbhq/cds-common';
import { IconButton } from '@cbhq/cds-web/buttons';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { TextBody, TextLegal, TextTitle2 } from '@cbhq/cds-web/typography';
import { HomebaseModal } from ':dapp/components/HomebaseModal/HomebaseModal';
import { useIsMobile } from ':dapp/hooks/useIsMobile';
import { useIsWebview } from ':dapp/hooks/useIsWebview';
import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';
import { OCSShareBar } from './OCSShareBar';
import { OCSSummerPassShareable } from './OCSSummerPassShareable';
const messages = defineMessages({
  shareDescription: {
    defaultMessage: "Refer a friend and you'll get 500 points after they complete their first experience on Onchain Summer.",
    description: 'share summer pass modal description'
  },
  shareInfo: {
    defaultMessage: 'The maximum amount of points able to be earned from referrals is 200,000 per address.',
    description: 'share summer pass modal info'
  },
  shareSummerPass: {
    defaultMessage: 'Refer friends, earn points',
    description: 'header for refer friends'
  },
  shareSummerPassShort: {
    defaultMessage: 'Refer friends',
    description: 'header for refer friends for mobile'
  },
  downloadPass: {
    defaultMessage: 'Download Summer Pass',
    description: 'download pass cta'
  }
});
export const testID = 'shareModalData';
export const shareableModalWidth = '480px';
const summerpassModalMobileClass = "s1no0ja9";
type OCSSummerPassShareModalProps = {
  closeModal: () => void;
};
export function OCSSummerPassShareModal({
  closeModal
}: OCSSummerPassShareModalProps) {
  const {
    formatMessage
  } = useIntl();
  const isMobile = useIsMobile();
  const {
    isAndroid,
    isWebView
  } = useIsWebview();
  const summerPassRef = useRef<HTMLElement | null>(null);
  const [downloadReady, {
    toggleOn: enableDownload
  }] = useToggler(false);
  const {
    account
  } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS
  });
  const {
    userState
  } = useGetUserState(account?.primaryAddress);
  const referralUrl = useMemo(() => {
    const {
      protocol,
      host
    } = window.location;
    const ocsUrl = `${protocol}//${host}/summer/share/summerpass`;
    return `${ocsUrl}?referral_id=${userState?.referralData.referralCode}&summerpass_id=${account?.primaryAddress}`;
  }, [userState, account]);

  /* istanbul ignore next */
  const handleSummerPassDownload = useCallback(async () => {
    if (!summerPassRef?.current) return;
    const element = summerPassRef.current;
    const dataUrl = await toJpeg(element, {
      quality: 1,
      cacheBust: true
    });
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      const fileName = `coinbase-summer-pass-${account?.primaryAddress || ''}.jpeg`;
      link.download = fileName;
      if (isWebView && isAndroid) {
        // for Android webview, we have to handle this differently and link-clicking tries to parse the not-supported "data:image/jpeg" url -> send as a message to android webview and handle message on RN
        window.ReactNativeWebView?.postMessage(JSON.stringify({
          data: dataUrl,
          fileName
        }));
      } else {
        link.href = dataUrl;
        link.click();
        link.remove();
      }
    } else {
      window.open(dataUrl);
    }
  }, [account?.primaryAddress, isWebView, isAndroid]);
  return <HomebaseModal visible testID="summerpass-share-modal" size="small" onClose={closeModal} contentOverflow="auto" footer={<></>} spacingHorizontal={isMobile ? 3 : undefined} contentSpacingTop={isMobile ? 1 : undefined} classOverride={isMobile ? summerpassModalMobileClass : undefined} footerSpacingBottom={isMobile ? 3 : undefined} header={<HStack spacingTop={2} spacingBottom={isMobile ? 1 : 2} spacingStart={isMobile ? 3 : 4} spacingEnd={2} justifyContent="space-between" alignItems="center" width="100%">
          <TextTitle2 as="p">
            {isMobile ? formatMessage(messages.shareSummerPassShort) : formatMessage(messages.shareSummerPass)}
          </TextTitle2>
          <IconButton testID="homebase-modal-close" name="close" compact transparent onPress={closeModal} />
        </HStack>}>
      <VStack alignItems="center" testID={testID} maxWidth={shareableModalWidth} width="100%">
        <Box width="100%">
          <OCSSummerPassShareable closeParentModal={closeModal} ref={summerPassRef} onReady={enableDownload} />
        </Box>
        {downloadReady && <Box width="100%" justifyContent="center" spacingTop={isMobile ? 2 : 4} spacingBottom={isMobile ? 0 : 2}>
            <VStack width="100%">
              <ThemeProvider spectrum="light">
                <Button testID="ocs-download-pass-btn" onPress={handleSummerPassDownload} variant="secondary" block endIcon="download">
                  {formatMessage(messages.downloadPass)}
                </Button>
              </ThemeProvider>
            </VStack>
          </Box>}
        <TextBody as="p" color="foregroundMuted" spacingTop={2} spacingBottom={isMobile ? 1 : 2}>
          {formatMessage(messages.shareDescription)}
        </TextBody>
        <OCSShareBar shareUrl={referralUrl} copyUrl={referralUrl} />
        <HStack spacingVertical={2}>
          <Icon color="foregroundMuted" name="info" size="xs" spacingTop={0.5} />
          <TextLegal as="p" color="foregroundMuted" spacingStart={1}>
            {formatMessage(messages.shareInfo)}
          </TextLegal>
        </HStack>
      </VStack>
    </HomebaseModal>;
}

require("./OCSSummerPassShareModal.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./OCSSummerPassShareModal.tsx");