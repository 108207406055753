import { useMemo } from 'react';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';

import { GAME_ID, GAMIFICATION_URL } from './config';

export type ReferrerResponse = {
  user_address: `0x${string}`;
  count: number;
};

export function useGetTopReferrers(handleError?: (err?: Error) => void) {
  const fetchQuery = async () => {
    const response = await fetch(`${GAMIFICATION_URL}/referrals/top-referrers?gameId=${GAME_ID}`);

    return response.json();
  };

  const { data, isError, isLoading } = useQuery<{ data: ReferrerResponse[] }, Error>(
    ['game_top_referrers'],
    fetchQuery,
    {
      onError: /* istanbul ignore next */ function handleOnError(err?: Error) {
        if (handleError) {
          handleError(err);
        }
      },
    },
  );

  const topReferrers = useMemo(() => {
    const results = data?.data ?? [];

    return results.map((referrer) => ({
      userAddress: referrer.user_address,
      count: referrer.count,
    }));
  }, [data?.data]);

  return { topReferrers, isError, isLoading };
}
