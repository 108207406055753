import { defineMessages, useIntl } from 'react-intl';
import { useGetUserRank } from 'cb-wallet-data/hooks/Gamification/useGetUserRank';
import { AccountWithBalance } from 'cb-wallet-data/stores/Accounts/hooks/useAccountsWithBalances';
import { PublicProfile } from 'cb-wallet-data/stores/DecentralizedID/types';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import { Box, Spacer } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle1 } from '@cbhq/cds-web/typography';

import { OCSLeaderboardItem } from './OCSLeaderboardItem';

const messages = defineMessages({
  componentTitle: {
    defaultMessage: 'Top players this summer',
    description: 'title for leaderboard',
  },
  you: {
    defaultMessage: 'you',
    description: 'heading for your rank in OCS leaderboard',
  },
});

type YourScoreSectionProps = YourScoreProps;

export function YourScoreSection(props: YourScoreSectionProps) {
  const { formatMessage } = useIntl();

  return (
    <>
      <TextTitle1 as="h3"> {formatMessage(messages.componentTitle)}</TextTitle1>
      <YourScore {...props} />
    </>
  );
}

type YourScoreProps = {
  account?: AccountWithBalance;
  getUserProfileName: (userAddress: string) => string | undefined;
  userProfiles: Record<string, PublicProfile>;
};

function YourScore({ account, getUserProfileName, userProfiles }: YourScoreProps) {
  const { formatMessage } = useIntl();
  const { setGamificationApiIssue } = usePageOutageContext();
  const { userRank, isLoading } = useGetUserRank(account?.primaryAddress, setGamificationApiIssue);

  if (isLoading) {
    return (
      <Box
        testID="loader-box"
        width="100%"
        height={82}
        background="secondary"
        borderRadius="roundedXLarge"
      />
    );
  }

  return userRank ? (
    <>
      <Spacer vertical={2} />
      <TextBody as="p"> {formatMessage(messages.you).toUpperCase()}</TextBody>
      <OCSLeaderboardItem
        rank={Number(userRank.rank)}
        name={getUserProfileName(userRank.userAddress)}
        points={userRank.currentScore}
        userAddress={userRank.userAddress}
        inverse
        userProfile={userProfiles[userRank.userAddress]}
      />
    </>
  ) : null;
}
