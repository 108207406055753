import { IconButton } from '@cbhq/cds-web/buttons';
import { useCarousel } from './CarouselProvider';
export function CarouselArrows() {
  const {
    prev,
    next
  } = useCarousel();

  /* we need to reduce the width of the component on mobile width
  return (
    <HStack gap={4}>
      <IconButton testID="carousel-arrow-left" name="caretLeft" onPress={prev} />
      <IconButton testID="carousel-arrow-right" name="caretRight" onPress={next} />
    </HStack>
  );
  */

  return <div className={"d12lzbb0"}>
      <IconButton testID="carousel-arrow-left" name="caretLeft" onPress={prev} />
      <IconButton testID="carousel-arrow-right" name="caretRight" onPress={next} />
    </div>;
}

require("./CarouselArrows.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./CarouselArrows.tsx");