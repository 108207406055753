import { useMemo } from 'react';
import { PublicProfile } from 'cb-wallet-data/stores/DecentralizedID/types';
import { getTruncatedAddress } from 'cb-wallet-data/utils/getTruncatedAddress';

type Props = {
  profiles: PublicProfile[] | undefined;
};

export function getProfileName(profiles: PublicProfile[] | undefined): string {
  if (!profiles?.length) return '';

  const name =
    (profiles[0].primaryDomainType === 'eth'
      ? profiles[0].ensDomainProfile?.name
      : profiles[0].subdomainProfile?.name) ?? '';
  return name !== '' ? name : getTruncatedAddress(profiles[0].address);
}

export function useProfileName({ profiles }: Props): string {
  return useMemo(() => getProfileName(profiles), [profiles]);
}
