import { ForwardedRef, forwardRef, Fragment, memo, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserState } from 'cb-wallet-data/hooks/Gamification/useUserOCSProfile';
import { usePublicProfileByAddresses } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddresses';
import { Button } from 'wallet-cds-web/components/Button';
import { PartialPaletteConfig, SpacingScale } from '@cbhq/cds-common';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, Spacer, VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { TextBody, TextLabel2, TextLegal, TextTitle2, TextTitle4 } from '@cbhq/cds-web/typography';
import { useConnectButton } from ':dapp/components/ConnectButton/useConnectButton';
import { useIsMobile } from ':dapp/hooks/useIsMobile';
import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';
import { useProfileName } from ':dapp/hooks/useProfileName';
import { OCSAvatar } from './OCSAvatar';
import { OCSGamePassOfflineLogo } from './OCSGamePassOfflineLogo';
import { badgeIdToAbbreviation, badgeIdToUrl, desktopStatTinyText, messages, nuxEmptyBadgesLength, OCSSummerPassLoading, shareableHStackFull } from './OCSSummerPass';
import { OCSGamePassLogo } from './OCSSummerPassLogo';
import { PinkCircle } from './PinkCircle';
const summerPass = "s10kkpwo";
const compressedStatTinyText = "c9y5eqh";
const compressedStatMediumText = "clwfb9v";
const desktopMediumText = "d14cbl6g";
const offlineTitle = "o1q6ka4v";
const badgeTextClassOffline = "bf51nkr";
const badgeTextSpacerVertical: SpacingScale = 0.5;
type OCSSummerPassShareableProps = {
  closeParentModal?: () => void;
  onReady?: () => void;
};
export const OCSSummerPassShareable = memo(forwardRef(function OCSSummerPassShareable({
  closeParentModal,
  onReady
}: OCSSummerPassShareableProps, ref: ForwardedRef<HTMLElement>) {
  const {
    formatMessage,
    formatNumber
  } = useIntl();
  const isMobile = useIsMobile();
  const {
    isTablet,
    isTabletLandscape
  } = useBreakpoints();
  const {
    account
  } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS
  });
  const {
    profiles
  } = usePublicProfileByAddresses({
    addresses: account ? [account?.primaryAddress] : []
  });
  const profileName = useProfileName({
    profiles
  });
  const {
    userState,
    isLoading
  } = useGetUserState(account?.primaryAddress);
  const {
    handleOpenModal: handleOpenConnectModal
  } = useConnectButton({
    type: 'connect'
  });
  useEffect(function handleOnReady() {
    if (!isLoading && ref && profiles?.length && onReady) {
      onReady();
    }
  }, [isLoading, ref, profiles, onReady]);
  const levelBadgeSize = useMemo(() => isMobile ? 25 : 40, [isMobile]);
  const badgeTextClass = useMemo(() => isMobile ? compressedStatTinyText : desktopStatTinyText, [isMobile]);
  const handleOpenConnect = useCallback(() => {
    handleOpenConnectModal();
    if (closeParentModal) closeParentModal();
  }, [closeParentModal, handleOpenConnectModal]);
  const handleEmptyBadge = useCallback((badge: {
    name: string;
  }, offlineMode = false) => <VStack alignItems="center">
          <img width={offlineMode ? 56 : levelBadgeSize} src="/ocs/badges/level-locked.svg" alt="locked badge" />
          <Spacer vertical={offlineMode ? 1 : badgeTextSpacerVertical} />

          <TextLabel2 mono as="p" overflow="wrap" className={offlineMode ? badgeTextClassOffline : badgeTextClass}>
            {badge.name}
          </TextLabel2>
        </VStack>, [levelBadgeSize, badgeTextClass]);
  const getBadges = useCallback((currentLevel: string | undefined, maxBadges = 8) => {
    if (!currentLevel) return null;
    const badges = maxBadges - 1;
    const emptyBadges = [];
    // for other mobile & all desktop, show empty badges
    if (!isMobile) {
      for (let i = 0; i < badges - (userState?.summerPassDisplayBadges?.length || 0); i++) {
        emptyBadges.push({
          id: 10 + i,
          name: '?'
        });
      }
    }
    const levelBadge = <VStack alignItems="center">
            <img width={levelBadgeSize} src={`/ocs/badges/level-${currentLevel}.svg`} alt="level badge" />
            <Spacer vertical={badgeTextSpacerVertical} />
            <ThemeProvider scale="small">
              <TextLabel2 mono as="p" overflow="wrap" className={badgeTextClass}>
                {formatMessage(messages.lvl, {
            level: userState?.levelData?.currentLevel?.level
          })}
              </TextLabel2>
            </ThemeProvider>
          </VStack>;
    let horizontalGap: SpacingScale = 2;
    if (isMobile) horizontalGap = 1.5;
    return <HStack gap={horizontalGap} flexWrap="wrap" justifyContent="center"
    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
    style={{
      rowGap: '5px'
    }}>
            {levelBadge}
            {userState?.summerPassDisplayBadges?.slice(0, badges).map(badge => {
        return <VStack alignItems="center" key={badge.id} testID={`image-${badgeIdToUrl[badge.id]}`}>
                  <img width={levelBadgeSize} src={`/ocs/badges/${badgeIdToUrl[badge.id]}`} alt={`${badge.name} badge`} />
                  <Spacer vertical={badgeTextSpacerVertical} />
                  <Box maxWidth={isMobile ? 40 : 75}>
                    <ThemeProvider scale="small">
                      <TextLabel2 mono as="p" overflow="wrap" align="center" className={badgeTextClass}>
                        {badgeIdToAbbreviation[badge.id]}
                      </TextLabel2>
                    </ThemeProvider>
                  </Box>
                </VStack>;
      })}
            {emptyBadges.map(badge => {
        return <Box key={badge.id} testID="empty-badge">
                  {handleEmptyBadge(badge)}
                </Box>;
      })}
          </HStack>;
  }, [isMobile, levelBadgeSize, badgeTextClass, formatMessage, userState?.levelData?.currentLevel?.level, userState?.summerPassDisplayBadges, handleEmptyBadge]);
  const statsDiv = useMemo(() => {
    const score = userState?.scoreData?.currentScore ? formatNumber(userState.scoreData.currentScore) : '-';
    const tinyTextClass = isMobile ? compressedStatTinyText : desktopStatTinyText;
    const mediumTextClass = isMobile ? compressedStatMediumText : desktopMediumText;
    const pinkCircleSize = isMobile ? '6px' : '9px';
    const stackGapSize: SpacingScale = isMobile ? 1 : 1.5;
    return <HStack gap={1} justifyContent="space-between" className={shareableHStackFull}>
          <VStack gap={stackGapSize}>
            <Box alignItems="center">
              <PinkCircle width={pinkCircleSize} />
              <Spacer horizontal={0.5} />
              <TextLabel2 mono as="p" className={tinyTextClass}>
                {formatMessage(messages.points).toUpperCase()}
              </TextLabel2>
            </Box>
            <TextTitle2 mono as="h3" className={mediumTextClass}>
              {score}
            </TextTitle2>
          </VStack>
          <VStack gap={stackGapSize}>
            <Box alignItems="center">
              <PinkCircle width={pinkCircleSize} />
              <Spacer horizontal={0.5} />
              <TextLabel2 mono as="p" className={tinyTextClass}>
                {formatMessage(messages.referrals).toUpperCase()}
              </TextLabel2>
            </Box>
            <TextTitle2 mono as="h3" className={mediumTextClass}>
              {userState?.referralData?.numReferrals}
            </TextTitle2>
          </VStack>
          <VStack gap={stackGapSize}>
            <Box alignItems="center">
              <PinkCircle width={pinkCircleSize} />
              <Spacer horizontal={0.5} />
              <TextLabel2 mono as="p" className={tinyTextClass}>
                {formatMessage(messages.completed).toUpperCase()}
              </TextLabel2>
            </Box>
            <TextTitle2 mono as="h3" className={mediumTextClass}>
              {userState?.numChallengesCompleted}
            </TextTitle2>
          </VStack>
        </HStack>;
  }, [userState?.scoreData?.currentScore, userState?.referralData?.numReferrals, userState?.numChallengesCompleted, formatNumber, isMobile, formatMessage]);
  const summerPassTop = useMemo(() => {
    if (isMobile) {
      return <HStack spacingHorizontal={2} justifyContent="space-between" gap={1.5}>
            <Box>
              <OCSAvatar width={35} height={35} publicProfile={profiles[0]} />
              <Spacer horizontal={1} />
              <VStack>
                <TextLegal as="p">{profileName}</TextLegal>
                <TextLegal as="p" className={`${compressedStatTinyText}`}>
                  {formatMessage(messages.level, {
                level: userState?.levelData?.currentLevel?.level
              }).toUpperCase()}
                </TextLegal>
              </VStack>
            </Box>
            <HStack justifyContent="space-between">
              {getBadges(userState?.levelData?.currentLevel?.level, 3)}
            </HStack>
          </HStack>;
    }
    return <HStack spacingHorizontal={2} justifyContent="space-between">
          <Box>
            <OCSAvatar width={58} height={58} publicProfile={profiles[0]} />
            <Spacer horizontal={1.5} />
            <VStack>
              <TextTitle4 as="p">{profileName}</TextTitle4>
              <TextLegal as="p" className={desktopStatTinyText}>
                {formatMessage(messages.level, {
              level: userState?.levelData?.currentLevel?.level
            }).toUpperCase()}
              </TextLegal>
            </VStack>
          </Box>
          {getBadges(userState?.levelData?.currentLevel?.level, 3)}
        </HStack>;
  }, [formatMessage, getBadges, isMobile, userState?.levelData, profileName, profiles]);
  const summerPassBottom = useMemo(() => {
    if (isMobile) {
      return <VStack spacingHorizontal={1}>
            <HStack spacingHorizontal={2} justifyContent="space-between">
              {statsDiv}
            </HStack>
          </VStack>;
    }
    if (isTabletLandscape || isTablet) {
      return <HStack spacingHorizontal={2} justifyContent="space-between">
            {statsDiv}
          </HStack>;
    }
    return <HStack spacingHorizontal={2} justifyContent="space-between">
          <HStack spacingHorizontal={2} justifyContent="space-between" className={shareableHStackFull}>
            {statsDiv}
          </HStack>
        </HStack>;
  }, [isMobile, isTabletLandscape, isTablet, statsDiv]);
  const overallGap = useMemo(() => {
    if (isMobile) return 2;
    return 3;
  }, [isMobile]);
  const paletteOverride: PartialPaletteConfig = useMemo(() => ({
    primary: 'pink70'
  }), []);
  const offlineView = useMemo(() => {
    if (!profiles?.length) {
      const emptyBadges = [];
      for (let i = 0; i < nuxEmptyBadgesLength; i++) {
        emptyBadges.push({
          id: i,
          name: '?'
        });
      }
      const offlineBadges = <Box justifyContent="center" testID="offline-badges">
            <HStack gap={isMobile ? 2 : 3}>
              {emptyBadges.map(badge => {
            return <Fragment key={badge.id}>{handleEmptyBadge(badge, true)}</Fragment>;
          })}
            </HStack>
          </Box>;
      const offlineCardContent = <>
            <Box alignSelf="center" spacingHorizontal={2}>
              <OCSGamePassOfflineLogo />
            </Box>
            <VStack gap={1} maxWidth={580} alignItems="center">
              <TextTitle2 as="h3" align="center" className={offlineTitle}>
                {formatMessage(messages.offlineTitle)}
              </TextTitle2>
              <TextBody spacingHorizontal={2} as="p" align="center" dangerouslySetColor="#D2D2D2">
                {formatMessage(messages.offlineSubtitle)}
              </TextBody>
            </VStack>
            {offlineBadges}
            <Box width="100%" justifyContent="center" alignSelf={isMobile ? 'center' : 'auto'}>
              <div style={{
            width: '100%'
          }}>
                <ThemeProvider palette={paletteOverride}>
                  <Button block compact variant="primary" onPress={handleOpenConnect} testID="getPassBtn-shareable">
                    {formatMessage(messages.offlineButton)}
                  </Button>
                </ThemeProvider>
              </div>
            </Box>
          </>;
      return <VStack dangerouslySetBackground="#0052FF" className={summerPass} spacingVertical={2} flexBasis={0} flexGrow={1} flexShrink={1} width="100%" gap={3}>
            <HStack alignItems="center" spacingHorizontal={2} justifyContent={isMobile ? 'space-between' : 'flex-start'} width="100%">
              <Icon name="globe" size={isMobile ? 'l' : 'l'} spacingEnd={2} color="foreground" />
              <OCSGamePassLogo size="m" />
            </HStack>
            <VStack gap={2} justifyContent="center" alignItems="center" spacingHorizontal={2}>
              {offlineCardContent}
            </VStack>
          </VStack>;
    }
    return null;
  }, [formatMessage, handleEmptyBadge, handleOpenConnect, isMobile, paletteOverride, profiles?.length]);
  return <VStack width="100%">
        <Box testID="ocs-summer-pass-shareable-wrapper" width="100%" ref={ref}>
          {isLoading ? <OCSSummerPassLoading /> : offlineView || <VStack dangerouslySetBackground="#0052FF" className={summerPass} spacingVertical={2} flexBasis={0} flexGrow={1} flexShrink={1} width="100%" gap={overallGap} testID="ocs-summer-pass-shareable">
                <HStack width="100%" alignItems="center" spacingHorizontal={2} justifyContent="space-between">
                  <Box alignItems="center" justifyContent="flex-start" width="100%">
                    <Icon name="globe" size={isMobile ? 's' : 'l'} spacingEnd={isMobile ? undefined : 2} color="foreground" />
                    <OCSGamePassLogo size={isMobile ? 's' : 'm'} />
                  </Box>
                </HStack>
                {summerPassTop}
                <Box height={1} dangerouslySetBackground="white" />

                {summerPassBottom}
              </VStack>}
        </Box>
      </VStack>;
}));

require("./OCSSummerPassShareable.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./OCSSummerPassShareable.tsx");