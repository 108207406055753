import { Fragment } from 'react';
import { useGetTopReferrers } from 'cb-wallet-data/hooks/Gamification/useGetTopReferrers';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import { ResponsiveGridProps } from '@cbhq/cds-common';
import { Box, Grid, VStack } from '@cbhq/cds-web/layout';

import { colors, ReferrerCard } from ':dapp/components/Card/ReferrerCard';

export const testID = 'ocs-top-referrers-grid';

const responsiveGrid: ResponsiveGridProps = {
  phone: {
    columns: 1,
    gap: 3,
  },
  tablet: {
    columns: 2,
    gap: 3,
  },
  desktop: {
    columns: 5,
  },
};

export function OCSTopReferrers() {
  const { setGamificationApiIssue } = usePageOutageContext();
  const data = useGetTopReferrers(setGamificationApiIssue);

  return (
    <Grid testID={testID} gap={5} columns={5} responsiveConfig={responsiveGrid}>
      <TopReferrers data={data} />
    </Grid>
  );
}

type TopReferrersProps = {
  data: ReturnType<typeof useGetTopReferrers>;
};

function TopReferrers({ data }: TopReferrersProps) {
  if (data.isLoading) {
    return <TopReferrersLoading />;
  }

  if (data.topReferrers.length === 0) {
    return null;
  }

  return (
    <>
      {data.topReferrers.slice(0, 5).map((referrer, i) => (
        <ReferrerCard
          key={`ocs-top-referrers-${referrer.userAddress}`}
          address={referrer.userAddress}
          referrals={referrer.count}
          rank={i + 1}
          colorScheme={colors[i]}
        />
      ))}
    </>
  );
}

function TopReferrersLoading() {
  return (
    <Fragment key="top-referrers-loading">
      {[...Array(5)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <VStack width={200} gap={2} key={index}>
          <Box width="100%" height={200} background="secondary" borderRadius="roundedXLarge" />
          <VStack width="100%" gap={0.5}>
            <Box width="100%" height={28} background="secondary" />
            <Box width="100%" height={28} background="secondary" />
          </VStack>
        </VStack>
      ))}
    </Fragment>
  );
}
