import { useMemo } from 'react';

import { useIsMobile } from './useIsMobile';

export function useIsWebview() {
  const isMobile = useIsMobile();
  return useMemo(() => {
    const base = {
      isIos: false,
      isAndroid: false,
      isWebView: false,
    };

    if (isMobile) {
      const navigator = window.navigator;
      const userAgent = navigator.userAgent;
      const normalizedUserAgent = userAgent.toLowerCase();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const standalone: boolean = (navigator as any).standalone;

      const isIos =
        /ip(ad|hone|od)/.test(normalizedUserAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      const isAndroid = normalizedUserAgent.includes('android');
      const isSafari = normalizedUserAgent.includes('safari');
      const isWebView =
        (isAndroid && normalizedUserAgent.includes('; wv)')) || (isIos && !standalone && !isSafari);

      return {
        isIos,
        isAndroid,
        isWebView,
      };
    }
    return base;
  }, [isMobile]);
}
