import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { PublicProfile } from 'cb-wallet-data/stores/DecentralizedID/types';
import { getTruncatedAddress } from 'cb-wallet-data/utils/getTruncatedAddress';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';

import { useIsMobile } from ':dapp/hooks/useIsMobile';

import { OCSAvatar } from '../OCSAvatar';

type LeaderboardItemProp = {
  rank: number;
  name?: string;
  points: number;
  inverse: boolean;
  userAddress: string;
  userProfile: PublicProfile;
};

const messages = defineMessages({
  points: {
    defaultMessage: 'Points',
    description: 'Overall OCS Score',
  },
});

export function OCSLeaderboardItem({
  rank,
  name,
  points,
  inverse,
  userAddress,
  userProfile,
}: LeaderboardItemProp) {
  const { formatMessage, formatNumber } = useIntl();
  const isMobile = useIsMobile();
  const textBodyColor = useMemo(() => (inverse ? 'primaryForeground' : 'foreground'), [inverse]);
  if (!name && !userAddress) {
    return null;
  }
  const displayName = name || getTruncatedAddress(userAddress);

  return (
    <Box
      dangerouslySetBackground={!inverse ? '#1E2025' : '#FFFFFF'}
      borderRadius="roundedMedium"
      spacingVertical={1}
      spacingHorizontal={3}
    >
      {isMobile ? (
        <HStack width="100%">
          <HStack gap={2} alignItems="center">
            <TextBody as="span" noWrap color={textBodyColor}>
              {rank}
            </TextBody>
            <OCSAvatar publicProfile={userProfile} width={50} height={50} />
          </HStack>
          <VStack spacingHorizontal={2}>
            <TextBody as="span" noWrap color={textBodyColor}>
              {displayName}
            </TextBody>
            <TextBody as="span" noWrap color={textBodyColor}>
              {`${formatNumber(points)} ${formatMessage(messages.points)}`}
            </TextBody>
          </VStack>
        </HStack>
      ) : (
        <HStack justifyContent="space-between" width="100%" alignItems="center">
          <HStack gap={2} alignItems="center">
            <TextBody as="span" noWrap color={textBodyColor}>
              {rank}
            </TextBody>
            <OCSAvatar publicProfile={userProfile} width={50} height={50} />
            <TextBody as="span" noWrap color={textBodyColor}>
              {displayName}
            </TextBody>
          </HStack>
          <TextBody as="span" noWrap color={textBodyColor}>
            {`${formatNumber(points)} ${formatMessage(messages.points)}`}
          </TextBody>
        </HStack>
      )}
    </Box>
  );
}
