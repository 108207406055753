import { useQuery } from 'cb-wallet-data/hooks/useQuery';

import { GAME_ID, GAMIFICATION_URL } from './config';

export type PlayerRank = {
  rank: string;
  userAddress: `0x${string}`;
  currentScore: number;
};

export function useGetUserRank(
  walletAddress: string | undefined,
  handleError?: (err?: Error) => void,
) {
  const fetchQuery = async () => {
    const response = await fetch(
      `${GAMIFICATION_URL}/leaderboard/rank?userAddress=${walletAddress}&gameId=${GAME_ID}`,
    );

    return response.json();
  };

  const { data, isError, isLoading, error, refetch } = useQuery<PlayerRank, Error>(
    ['game_userRank'],
    fetchQuery,
    {
      enabled: !!walletAddress,
      onError: /* istanbul ignore next */ function handleOnError(err?: Error) {
        if (handleError) {
          handleError(err);
        }
      },
    },
  );

  return { userRank: data, isError, isLoading, error, refetch };
}
