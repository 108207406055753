import { useMutation } from '@tanstack/react-query';

import { GAME_ID, GAMIFICATION_URL } from './config';

type Params = {
  walletAddress: string;
  badgeId: string;
};

export function useMutateBadgeClaim(handleError?: (err?: Error) => void) {
  const { mutate, isLoading, error, data } = useMutation({
    mutationFn: async function mutateBadgeClaim({ walletAddress, badgeId }: Params) {
      const response = await fetch(`${GAMIFICATION_URL}/badges/claim`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          gameId: GAME_ID,
          userAddress: walletAddress,
          badgeId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to claim badge');
      }

      return response.json();
    },
    onError: /* istanbul ignore next */ function handleOnError(err?: Error) {
      if (handleError) {
        handleError(err);
      }
    },
  });

  return {
    claimBadge: mutate,
    isLoading,
    error,
    data,
  };
}
