import { defineMessages } from 'react-intl';

import { BadgeCardType } from './OCSEarnBadgesSection';

export const messages = defineMessages({
  collectorTitle: {
    defaultMessage: 'Collector',
    description: 'Title for the collector badge',
  },
  collectorDescription: {
    defaultMessage: "You're a collectooor. Hold 20 NFTs on Base to earn this badge.",
    description: 'Description for the collector badge',
  },
  baseTxTitle: {
    defaultMessage: 'Based: {count} transactions',
    description: 'Title for the Base TX badges',
  },
  baseTxDescription: {
    defaultMessage: 'Claim this badge once you reach {count} transactions on Base.',
    description: 'Description for the Base TX badges',
  },
  coinbaseOneTitle: {
    defaultMessage: 'Coinbase One',
    description: 'Title for the Coinbase One badge',
  },
  coinbaseOneDescription: {
    defaultMessage:
      'Join Coinbase One and verify a wallet to claim this badge, plus get zero-trading fees on Coinbase and much more. New members receive a 7-day free trial.',
    description: 'Description for the Coinbase One badge',
  },
  SWCTitle: {
    defaultMessage: 'Stand With Crypto',
    description: 'Title for the SWC badge',
  },
  SWCDescription: {
    defaultMessage: 'Join Stand With Crypto to fight for your right to own crypto',
    description: 'Description for the SWC badge',
  },
  saverTitle: {
    defaultMessage: 'Saver',
    description: 'Title for the Saver badge',
  },
  saverDescription: {
    defaultMessage: 'You know how to save onchain. You have 100 USDC or more on Base.',
    description: 'Description for the Saver badge',
  },
  builderTitle: {
    defaultMessage: 'Builder',
    description: 'Title for the Builder badge',
  },
  builderDescription: {
    defaultMessage: "You're building on Base. You have the buildathon NFT in your wallet.",
    description: 'Description for the Builder badge',
  },
  traderTitle: {
    defaultMessage: 'Trader',
    description: 'Title for the Trader badge',
  },
  traderDescription: {
    defaultMessage: "You're trading portfolio is looking great. You hold 5 tokens on Base.",
    description: 'Description for the Trader badge',
  },
  badgeClaimCtaText: {
    defaultMessage: 'Claim badge',
    description: 'Claim badge button text',
  },
  claimedCtaText: {
    defaultMessage: 'Claimed',
    description: 'Claimed badge button text',
  },
});

export const errorMessages = defineMessages({
  cb1ErrorMessage: {
    defaultMessage:
      'Join Coinbase One and verify a wallet to claim this badge, plus get zero-trading fees on Coinbase and much more. New members receive a 7-day free trial.',
    description: 'Error message for Coinbase One badge',
  },
  baseTxErrorMessage: {
    defaultMessage: `You're not at {count} transactions yet! Head to the Play tab to find more to do on Base.`,
    description: 'Error message for Base TX badge',
  },
  collectorErrorMessage: {
    defaultMessage: `You need more NFTs! Head to the Play tab to find more awesome mints.`,
    description: 'Error message for collector badge',
  },
  swcErrorMessage: {
    defaultMessage: `You need the Stand With Crypto Membership NFT to claim this badge.`,
    description: 'Error message for SWC badge',
  },
  saverErrorMessage: {
    defaultMessage: `You need more USDC on Base to claim this badge.`,
    description: 'Error message for Saver badge',
  },
  builderErrorMessage: {
    defaultMessage: `You need the Buildathon NFT! `,
    description: 'Error message for Builder badge',
  },
  traderErrorMessage: {
    defaultMessage: `You need more tokens! Trade some tokens to earn this badge. See what tokens are trending on Base on Coinbase Wallet.`,
    description: 'Error message for Trader badge',
  },
});

export const ctaMessages = defineMessages({
  baseTxCtaText: {
    defaultMessage: 'Discover',
    description: 'CTA text for Base TX badge',
  },
  collectorCtaText: {
    defaultMessage: 'Find NFTs',
    description: 'CTA text for collector badge',
  },
  swcCtaText: {
    defaultMessage: 'Mint NFT',
    description: 'CTA text for SWC badge',
  },
  saverCtaText: {
    defaultMessage: 'Buy or Swap on Coinbase Wallet',
    description: 'CTA text for Saver badge',
  },
  builderCtaText: {
    defaultMessage: 'Mint NFT',
    description: 'CTA text for Builder badge',
  },
  traderCtaText: {
    defaultMessage: 'Go to Coinbase Wallet',
    description: 'CTA text for Trader badge',
  },
  cb1CtaText: {
    defaultMessage: 'Sign up',
    description: 'CTA text for CB1 badge',
  },
});

export const badgeCards: BadgeCardType[] = [
  {
    id: '1',
    title: messages.SWCTitle,
    imageUrl: '/ocs/badges/cardSWC.png',
    shortDescription: messages.SWCDescription,
    ctaText: ctaMessages.swcCtaText,
    errorMessage: errorMessages.swcErrorMessage,
    targetUrl: 'https://www.standwithcrypto.org/action/sign-up',
  },
  {
    id: '2',
    title: messages.coinbaseOneTitle,
    imageUrl: '/ocs/badges/cardCoinbaseOne.png',
    shortDescription: messages.coinbaseOneDescription,
    ctaText: ctaMessages.cb1CtaText,
    errorMessage: errorMessages.cb1ErrorMessage,
    targetUrl: 'https://www.coinbase.com/one?action=onchain_verify&referrer=pano&member=true',
  },
  {
    id: '3',
    title: messages.builderTitle,
    imageUrl: '/ocs/badges/cardBuilder.png',
    shortDescription: messages.builderDescription,
    ctaText: ctaMessages.builderCtaText,
    errorMessage: errorMessages.builderErrorMessage,
    targetUrl: 'https://homebase.coinbase.com/nft/mint/buildathon',
  },
  {
    id: '4',
    title: messages.collectorTitle,
    imageUrl: '/ocs/badges/cardCollector.png',
    shortDescription: messages.collectorDescription,
    ctaText: ctaMessages.collectorCtaText,
    errorMessage: errorMessages.collectorErrorMessage,
    targetUrl: '/',
  },
  {
    id: '5',
    title: messages.traderTitle,
    imageUrl: '/ocs/badges/cardTrader.png',
    shortDescription: messages.traderDescription,
    ctaText: ctaMessages.traderCtaText,
    errorMessage: errorMessages.traderErrorMessage,
    targetUrl: '/?drawer=swap',
  },

  {
    id: '6',
    title: messages.saverTitle,
    imageUrl: '/ocs/badges/cardSaver.png',
    shortDescription: messages.saverDescription,
    ctaText: ctaMessages.saverCtaText,
    errorMessage: errorMessages.saverErrorMessage,
    targetUrl: '/',
  },
  {
    id: '7',
    title: messages.baseTxTitle,
    shortDescription: messages.baseTxDescription,
    errorMessage: errorMessages.baseTxErrorMessage,
    imageUrl: '/ocs/badges/card10BaseTX.png',
    ctaText: ctaMessages.baseTxCtaText,
    targetUrl: '/ocs/play',
    value: '10',
  },
  {
    id: '8',
    title: messages.baseTxTitle,
    shortDescription: messages.baseTxDescription,
    errorMessage: errorMessages.baseTxErrorMessage,
    imageUrl: '/ocs/badges/card50BaseTX.png',
    ctaText: ctaMessages.baseTxCtaText,
    targetUrl: '/ocs/play',
    value: '50',
  },
  {
    id: '9',
    title: messages.baseTxTitle,
    shortDescription: messages.baseTxDescription,
    errorMessage: errorMessages.baseTxErrorMessage,
    imageUrl: '/ocs/badges/card100BaseTX.png',
    ctaText: ctaMessages.baseTxCtaText,
    targetUrl: '/ocs/play',
    value: '100',
  },
  {
    id: '10',
    title: messages.baseTxTitle,
    shortDescription: messages.baseTxDescription,
    errorMessage: errorMessages.baseTxErrorMessage,
    imageUrl: '/ocs/badges/card1000BaseTX.png',
    ctaText: ctaMessages.baseTxCtaText,
    targetUrl: '/ocs/play',
    value: '1000',
  },
];
