import { ReactNode } from 'react';
import { Grid, HStack } from '@cbhq/cds-web/layout';
import { useCarousel } from './CarouselProvider';
export const testID = 'carousel-container';
type CarouselContainerProps = {
  children: ReactNode;
};
export function CarouselContainer({
  children
}: CarouselContainerProps) {
  const carousel = useCarousel();
  const gridStyle = "gmkxyrf";
  return <Grid>
      <HStack testID={testID} ref={carousel.carouselRef} className={gridStyle}>
        {children}
      </HStack>
    </Grid>;
}

require("./CarouselContainer.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./CarouselContainer.tsx");