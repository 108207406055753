import { useMutation } from '@tanstack/react-query';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';

import { GAME_ID, GAMIFICATION_URL } from './config';

export enum SpinOptionTypeEnum {
  POINTS = 'POINTS',
  USDC = 'USDC',
}

export type SpinOption = {
  id: number;
  points: number;
  odds: number;
  type: SpinOptionTypeEnum;
};

export type SpinData = {
  id: string;
  gameId: string;
  userAddress: string;
  hasAvailableSpin: boolean;
  lastSpinResult: SpinOption;
  totalSpins: number;
};

export type SpinTheWheelState = {
  spinOptions: SpinOption[];
  spinData: SpinData;
};

export function useGetSpinWheelState(
  walletAddress: string | undefined,
  handleError?: (err?: Error) => void,
) {
  const fetchQuery = async () => {
    const response = await fetch(
      `${GAMIFICATION_URL}/spin-the-wheel?gameId=${GAME_ID}&userAddress=${walletAddress}`,
    );

    return response.json();
  };

  const { data, isError, isLoading, error, refetch } = useQuery<SpinTheWheelState, Error>(
    ['game_spin_wheel'],
    fetchQuery,
    {
      enabled: !!walletAddress,
      onError: /* istanbul ignore next */ function handleOnError(err?: Error) {
        if (handleError) {
          handleError(err);
        }
      },
    },
  );

  return { spinState: data, isError, isLoading, error, refetch };
}

async function executeSpingWheel({ walletAddress }: { walletAddress: string }) {
  const response = await fetch(`${GAMIFICATION_URL}/spin-the-wheel/execute`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      gameId: '2',
      userAddress: walletAddress,
    }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`spin failed with error ${errorText}`);
  }

  return response.json();
}

export function useExecuteSpin(handleApiError?: (err?: Error) => void) {
  const { mutateAsync, isLoading, error, data } = useMutation({
    mutationFn: async (params: { walletAddress: string }) => executeSpingWheel(params),
    onError: /* istanbul ignore next */ function handleOnError(err?: Error) {
      if (handleApiError) {
        handleApiError(err);
      }
    },
  });

  return { mutateAsync, isLoading, error, data };
}
