import { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Grid, VStack } from '@cbhq/cds-web/layout';

import { CommunityAvatar, CommunityAvatarProps } from ':dapp/components/Card/CommunityAvatar';

import { OCSSectionHeader } from './OCSSectionHeader';

export const testID = 'ocs-communities-section';
const cdnBaseUrl = 'https://go.wallet.coinbase.com/static';

const messages = defineMessages({
  baseGodDescription: {
    defaultMessage:
      'Join the Disciples of B as we follow Big B to eternal salvation and spread His commandments onchain.',
    description: 'Description for the base god community',
  },

  degenDescription: {
    defaultMessage:
      'A fan favorite on Farcaster, $DEGEN is a token on Base often used to tip posts on Farcaster.',
    description: 'Description for the degen community',
  },

  basePaintDescription: {
    defaultMessage: 'Paint Together. Mint Daily. Visit our gallery today.',
    description: 'Description for the base paint community',
  },

  secretPikachuDescription: {
    defaultMessage: 'Ur next fav superchain arteest.',
    description: 'Description for the secret pikachu community',
  },

  ethxyDescription: {
    defaultMessage: 'Have fun this summer onchain and win prizes.',
    description: 'Description for the ethxy community',
  },

  frenpetDescription: {
    defaultMessage: 'Own and grow with your onchain fren. Join the discord.',
    description: 'Description for the frenpet community',
  },

  latashaDescription: {
    defaultMessage:
      'Latasha experiments with beat videos and meditation tracks from her home in LA.',
    description: 'Description for the latasha community',
  },

  refractionDescription: {
    defaultMessage:
      "We're an artist-owned community leading the next wave of digital art, music and culture.",
    description: 'Description for the refraction community',
  },

  ocsCommunitiesTitle: {
    defaultMessage: 'Communities on Base',
    description: 'Title for the communities on base section',
  },

  ocsCommunitiesSubtitle: {
    defaultMessage: 'Meet the builders and meme makers',
    description: 'Subtitle for the communities on base section',
  },
});

const communityCard: CommunityAvatarProps[] = [
  {
    name: 'Base God',
    text: messages.baseGodDescription,
    type: 'memes',
    url: 'https://twitter.com/tybasegod',
    image: `${cdnBaseUrl}/basegod.png`,
  },
  {
    name: 'Degen',
    text: messages.degenDescription,
    type: 'memes',
    url: 'https://twitter.com/degentokenbase',
    image: `${cdnBaseUrl}/degen.png`,
  },
  {
    name: 'Base Paint',
    text: messages.basePaintDescription,
    type: 'artists',
    url: 'https://twitter.com/basepaint_xyz',
    image: `${cdnBaseUrl}/basePaint.png`,
  },
  {
    name: 'Secret Pikachu',
    text: messages.secretPikachuDescription,
    type: 'artists',
    url: 'https://twitter.com/ttyl5h',
    image: `${cdnBaseUrl}/secretPikachu.png`,
  },
  {
    name: 'ETHXY',
    text: messages.ethxyDescription,
    type: 'games',
    url: 'https://plots.ethxy.com/',
    image: `${cdnBaseUrl}/ethxyimg.png`,
  },
  {
    name: 'Frenpet',
    text: messages.frenpetDescription,
    type: 'games',
    url: 'https://frenpet.xyz/',
    image: `${cdnBaseUrl}/frenpet.png`,
  },
  {
    name: 'Latasha',
    text: messages.latashaDescription,
    type: 'musicians',
    url: 'https://twitter.com/CallMeLatasha',
    image: `${cdnBaseUrl}/latasha.png`,
  },
  {
    name: 'Refraction',
    text: messages.refractionDescription,
    type: 'musicians',
    url: 'https://twitter.com/RefractionDAO',
    image: `${cdnBaseUrl}/refraction.png`,
  },
];

export function OCSCommunities() {
  const { formatMessage } = useIntl();

  const communityAvatars = communityCard.map((community) => (
    <CommunityAvatar key={community.name} {...community} />
  ));

  return (
    <VStack testID={testID}>
      <OCSSectionHeader
        title={formatMessage(messages.ocsCommunitiesTitle)}
        subtitle={formatMessage(messages.ocsCommunitiesSubtitle)}
        spacingVertical={4}
      />
      <Grid templateColumns="repeat(auto-fit, minmax(500px, 1fr))" gap={4} spacingBottom={4}>
        {communityAvatars}
      </Grid>
    </VStack>
  );
}

export const OCSCommunitiesSection = memo(OCSCommunities);
