import { useQuery } from 'cb-wallet-data/hooks/useQuery';

import { GAME_ID, GAMIFICATION_URL } from './config';
import { PlayerRank } from './useGetUserRank';

export type TopRankReturnType = {
  data: PlayerRank[];
};

export function useGetGameLeaderboard(page = 1, limit = 100, handleError?: (err?: Error) => void) {
  const fetchQuery = async () => {
    const response = await fetch(
      `${GAMIFICATION_URL}/leaderboard?gameId=${GAME_ID}&page=${page}&limit=${limit}`,
    );

    return response.json();
  };

  const { data, isError, isLoading, error, refetch } = useQuery<PlayerRank[], Error>(
    ['game_leaderboard'],
    fetchQuery,
    {
      onError: /* istanbul ignore next */ function handleOnError(err?: Error) {
        if (handleError) {
          handleError(err);
        }
      },
    },
  );

  return { leaderboard: data, isError, isLoading, error, refetch };
}
