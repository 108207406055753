import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { Button } from 'wallet-cds-web/components/Button';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, HStack, Spacer, VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { TextLabel2, TextTitle1 } from '@cbhq/cds-web/typography';

import { useConnectButton } from ':dapp/components/ConnectButton/useConnectButton';
import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';

import { OCSSpinWheelLogo } from './OCSSpinWheelLogo';
import { OCSSpinWheelUSDCLogo } from './OCSSpinWheelUSDCLogo';

const messages = defineMessages({
  title: {
    defaultMessage: 'Win points everyday',
    description: 'title',
  },
  subtitle: {
    defaultMessage: 'Spin the wheel daily and earn extra points with Coinbase Wallet',
    description: 'subtitle',
  },
  usdcTitle: {
    defaultMessage: 'Win USDC and points everyday',
    description: 'title',
  },
  usdcSubtitle: {
    defaultMessage: 'Earn USDC and extra points with Coinbase Wallet',
    description: 'subtitle',
  },
  button: {
    defaultMessage: 'Spin the wheel',
    description: 'call to action',
  },
});

type OCSSpinWheelBannerProps = {
  toggleOn: () => void;
};

export function OCSSpinWheelBanner({ toggleOn }: OCSSpinWheelBannerProps) {
  const { isDesktop, isTabletLandscape } = useBreakpoints();
  const { formatMessage } = useIntl();
  const isUsdcEnabled = useIsFeatureEnabled('ocs_spin_the_wheel_usdc');

  const wheelLogo = useMemo(() => {
    return isUsdcEnabled ? <OCSSpinWheelUSDCLogo /> : <OCSSpinWheelLogo />;
  }, [isUsdcEnabled]);

  const { handleOpenModal } = useConnectButton({
    type: 'connect',
  });

  const { hasPreferredWallet } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS,
  });

  const handleCTAClick = useCallback(() => {
    return hasPreferredWallet(WalletPreferenceFeature.OCS) ? toggleOn() : handleOpenModal();
  }, [handleOpenModal, hasPreferredWallet, toggleOn]);

  const infoDetails = useMemo(
    () => (
      <>
        <VStack gap={1} id="spin-the-wheel-banner">
          <TextTitle1 as="h3" color="secondary">
            {isUsdcEnabled ? formatMessage(messages.usdcTitle) : formatMessage(messages.title)}
          </TextTitle1>
          <ThemeProvider spectrum="light">
            <TextLabel2 as="p" color="foregroundMuted">
              {isUsdcEnabled
                ? formatMessage(messages.usdcSubtitle)
                : formatMessage(messages.subtitle)}
            </TextLabel2>
          </ThemeProvider>
          <Spacer vertical={1} />
          <Box width="200px" zIndex={1}>
            <ThemeProvider spectrum="dark">
              <Button variant="secondary" testID="spinBtn" onClick={handleCTAClick} compact>
                {formatMessage(messages.button)}
              </Button>
            </ThemeProvider>
          </Box>
        </VStack>
        {isDesktop && (
          <Box position="absolute" bottom={-135} right={30} maxWidth={300}>
            {wheelLogo}
          </Box>
        )}
      </>
    ),
    [isUsdcEnabled, formatMessage, handleCTAClick, isDesktop, wheelLogo],
  );

  return (
    <>
      {isTabletLandscape || isDesktop ? (
        <HStack
          dangerouslySetBackground="#F3F3F3"
          spacing={3}
          justifyContent="space-between"
          overflow="hidden"
          position="relative"
          borderRadius="roundedLarge"
        >
          {infoDetails}
          <Box position="absolute" top={-30} right={isDesktop ? 300 : 30} maxWidth={300}>
            {wheelLogo}
          </Box>
        </HStack>
      ) : (
        <VStack
          dangerouslySetBackground="#F3F3F3"
          spacing={3}
          overflow="hidden"
          borderRadius="roundedLarge"
          alignContent="center"
          justifyContent="center"
          alignSelf="center"
        >
          {infoDetails}
          <Box position="relative" height={155} alignSelf="center">
            <Box position="absolute" top={-70} left={-150} width={300}>
              {wheelLogo}
            </Box>
          </Box>
        </VStack>
      )}
    </>
  );
}
