import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useGetUserState } from 'cb-wallet-data/hooks/Gamification/useUserOCSProfile';
import { usePublicProfileByAddresses } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddresses';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Button } from 'wallet-cds-web/components/Button';
import { PartialPaletteConfig, SpacingScale, useToggler } from '@cbhq/cds-common';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, Spacer, VStack } from '@cbhq/cds-web/layout';
import { Pressable, ThemeProvider } from '@cbhq/cds-web/system';
import { TextBody, TextHeadline, TextLabel2, TextLegal, TextTitle2, TextTitle4 } from '@cbhq/cds-web/typography';
import { useConnectButton } from ':dapp/components/ConnectButton/useConnectButton';
import { useAutoClaimChallenges } from ':dapp/hooks/useAutoClaimChallenges';
import { useIsMobile } from ':dapp/hooks/useIsMobile';
import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';
import { useProfileName } from ':dapp/hooks/useProfileName';
import { OCSAvatar } from './OCSAvatar';
import { OCSBorderedIcon } from './OCSBorderedIcon';
import { OCSGamePassOfflineLogo } from './OCSGamePassOfflineLogo';
import { OCSGamePassLogo } from './OCSSummerPassLogo';
import { OCSSummerPassShareModal } from './OCSSummerPassShareModal';
import { PinkCircle } from './PinkCircle';
export const badgeIdToUrl: Record<number, string> = {
  0: 'level-locked.svg',
  1: 'swc.svg',
  2: 'cb1.svg',
  3: 'builder.svg',
  4: 'collector.svg',
  5: 'trader.svg',
  6: 'saver.svg',
  7: '10-tx.svg',
  8: '50-tx.svg',
  9: '100-tx.svg',
  10: '1000-tx.svg'
};
export const badgeIdToAbbreviation: Record<number, string> = {
  1: 'SWC',
  2: 'CB1',
  3: 'Builder'.toUpperCase(),
  4: 'Collector'.toUpperCase(),
  5: 'Trader'.toUpperCase(),
  6: 'Saver'.toUpperCase(),
  7: 'Based'.toUpperCase(),
  8: 'Based'.toUpperCase(),
  9: 'Based'.toUpperCase(),
  10: 'Based'.toUpperCase()
};
export const nuxEmptyBadgesLength = 4;
export const messages = defineMessages({
  points: {
    defaultMessage: 'points',
    description: 'Total user points in OCS'
  },
  referrals: {
    defaultMessage: 'Referrals',
    description: 'Total user refferals to OCS'
  },
  completed: {
    defaultMessage: 'Completed',
    description: 'Total user challenges completed in OCS'
  },
  offlineTitle: {
    defaultMessage: 'Track your onchain journey',
    description: 'offline call to action'
  },
  offlineSubtitle: {
    defaultMessage: 'Get your unique Summer Pass. Watch it evolve as you earn points and badges.',
    description: 'offline description'
  },
  offlineButton: {
    defaultMessage: 'Get pass',
    description: 'offline signup button'
  },
  level: {
    defaultMessage: 'Level {level}',
    description: 'label indicating users level'
  },
  lvl: {
    defaultMessage: 'LVL {level}',
    description: 'shorthand label for level'
  },
  shareSummerPass: {
    defaultMessage: 'Refer friends, earn points',
    description: 'header for refer friends'
  },
  shareSummerPassShort: {
    defaultMessage: 'Refer friends',
    description: 'header for refer friends for mobile'
  },
  faq: {
    defaultMessage: 'FAQ',
    description: 'Frequenty asked questions link'
  },
  refer: {
    defaultMessage: 'Refer',
    description: 'Refer a friend button'
  },
  spinWheelButton: {
    defaultMessage: 'SPIN TO EARN POINTS 🎡',
    description: 'Spin the wheel button'
  }
});
const summerPass = "s1n4hn2z";
type OCSSummerPassProps = {
  condensedView?: boolean;
  spinWheelToggle?: () => void;
};
export const mobileStatTinyText = "m10c6zkg";
export const mobileStatMediumText = "miuzd7m";
export const shareableHStackFull = "s1329a91";
export const desktopStatTinyText = "d1i0847y";
const defaultBadgeText = "d12b72s0";
const badgeSize = 55;
export function OCSGamePass({
  condensedView = false,
  spinWheelToggle
}: OCSSummerPassProps) {
  const router = useRouter();
  const {
    formatMessage,
    formatNumber
  } = useIntl();
  const isMobile = useIsMobile();
  const {
    handleOpenModal
  } = useConnectButton({
    type: 'connect'
  });
  const {
    isTablet,
    isTabletLandscape,
    isDesktop
  } = useBreakpoints();
  const [visible, {
    toggleOff,
    toggleOn
  }] = useToggler(false);
  const paletteOverride: PartialPaletteConfig = useMemo(() => ({
    primary: 'pink70'
  }), []);
  const {
    account
  } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS
  });
  const {
    profiles
  } = usePublicProfileByAddresses({
    addresses: account ? [account?.primaryAddress] : []
  });
  const profileName = useProfileName({
    profiles
  });
  const {
    userState,
    isLoading
  } = useGetUserState(account?.primaryAddress);
  useAutoClaimChallenges();
  const badgesStyle = useMemo(() => ({
    rowGap: '5px'
  }), []);
  const handleNavigateProgress = useCallback(() => {
    router.push('/ocs/progress').then(() => {
      window.location.hash = 'spin-the-wheel-banner';
    });
  }, [router]);
  const summerPassShareModal = useMemo(() => {
    if (!visible) return null;
    return <OCSSummerPassShareModal closeModal={toggleOff} />;
  }, [visible, toggleOff]);
  const handleEmptyBadge = useCallback((badge: {
    name: string;
  }) => {
    return <VStack alignItems="center">
        <img width={badgeSize} src="/ocs/badges/level-locked.svg" alt="locked badge" />
        <Spacer vertical={1} />

        <TextLabel2 mono as="p" overflow="wrap">
          {badge.name}
        </TextLabel2>
      </VStack>;
  }, []);
  const getBadges = useCallback((currentLevel: string | undefined, maxBadges = 8) => {
    if (!currentLevel) return null;
    const badges = maxBadges - 1;
    const emptyBadges = [];
    // for other mobile & all desktop, show empty badges
    for (let i = 0; i < badges - (userState?.summerPassDisplayBadges?.length || 0); i++) {
      emptyBadges.push({
        id: 10 + i,
        name: '?'
      });
    }
    const levelBadge = <VStack alignItems="center">
          <img width={badgeSize} src={`/ocs/badges/level-${currentLevel}.svg`} alt="level badge" />
          <Spacer vertical={1} />
          <TextLabel2 mono as="p" overflow="wrap" className={defaultBadgeText}>
            {formatMessage(messages.lvl, {
          level: userState?.levelData?.currentLevel?.level
        })}
          </TextLabel2>
        </VStack>;
    return <HStack gap={3} flexWrap="wrap" justifyContent="center" style={badgesStyle}>
          {levelBadge}
          {userState?.summerPassDisplayBadges?.slice(0, badges).map(badge => {
        return <VStack alignItems="center" key={badge.id} testID={`image-${badgeIdToUrl[badge.id]}`}>
                <img width={badgeSize} src={`/ocs/badges/${badgeIdToUrl[badge.id]}`} alt={`${badge.name} badge`} />
                <Spacer vertical={1} />
                <Box maxWidth={75}>
                  <TextLabel2 mono as="p" overflow="wrap" align="center" className={defaultBadgeText}>
                    {badgeIdToAbbreviation[badge.id]}
                  </TextLabel2>
                </Box>
              </VStack>;
      })}
          {emptyBadges.map(badge => {
        return <Pressable testID="empty-badge-btn" background="transparent" key={badge.id} onPress={handleNavigateProgress}>
                {handleEmptyBadge(badge)}
              </Pressable>;
      })}
        </HStack>;
  }, [formatMessage, userState?.levelData?.currentLevel?.level, userState?.summerPassDisplayBadges, badgesStyle, handleEmptyBadge, handleNavigateProgress]);
  const shareButton = useMemo(() => <Button block accessibilityLabel="Share button" testID="shareBtn" onClick={toggleOn} variant="primary">
        <HStack>
          <TextBody mono as="p">
            {formatMessage(messages.refer)}
          </TextBody>
          <Icon spacingStart={2} color="foreground" name="share" size="m" />
        </HStack>
      </Button>, [toggleOn, formatMessage]);
  const gapSpacing = useMemo(() => {
    if (isMobile) {
      return 2;
    }
    if (isTablet && !isTabletLandscape) {
      return 2;
    }
    return 8;
  }, [isMobile, isTablet, isTabletLandscape]);
  const ocsLogoSize = useMemo(() => {
    if (isMobile) return 'm';
    return 'l';
  }, [isMobile]);
  const statsDiv = useMemo(() => {
    const score = userState?.scoreData?.currentScore ? formatNumber(userState.scoreData.currentScore) : '-';
    const tinyTextClass = isMobile ? mobileStatTinyText : undefined;
    const mediumTextClass = isMobile ? mobileStatMediumText : undefined;
    const pinkCircleSize = isMobile ? '12px' : undefined;
    const stackGapSize: SpacingScale = isMobile ? 1 : 0;
    return <HStack gap={gapSpacing} justifyContent="space-between">
        <VStack gap={stackGapSize}>
          <Box alignItems="center">
            <PinkCircle width={pinkCircleSize} />
            <Spacer horizontal={0.5} />
            <TextLabel2 mono as="p" className={tinyTextClass}>
              {formatMessage(messages.points).toUpperCase()}
            </TextLabel2>
          </Box>
          <TextTitle2 as="h3" className={mediumTextClass}>
            {score}
          </TextTitle2>
        </VStack>
        <VStack gap={stackGapSize}>
          <Box alignItems="center">
            <PinkCircle width={pinkCircleSize} />
            <Spacer horizontal={0.5} />
            <TextLabel2 mono as="p" className={tinyTextClass}>
              {formatMessage(messages.referrals).toUpperCase()}
            </TextLabel2>
          </Box>
          <TextTitle2 as="h3" className={mediumTextClass}>
            {userState?.referralData?.numReferrals}
          </TextTitle2>
        </VStack>
        <VStack gap={stackGapSize}>
          <Box alignItems="center">
            <PinkCircle width={pinkCircleSize} />
            <Spacer horizontal={0.5} />
            <TextLabel2 mono as="p" className={tinyTextClass}>
              {formatMessage(messages.completed).toUpperCase()}
            </TextLabel2>
          </Box>
          <TextTitle2 as="h3" className={mediumTextClass}>
            {userState?.numChallengesCompleted}
          </TextTitle2>
        </VStack>
      </HStack>;
  }, [userState?.scoreData?.currentScore, userState?.referralData?.numReferrals, userState?.numChallengesCompleted, formatNumber, isMobile, gapSpacing, formatMessage]);
  const summerPassTop = useMemo(() => {
    if (condensedView && !isMobile) {
      return <HStack spacingHorizontal={2} justifyContent="space-between" gap={2}>
          <Box>
            <OCSAvatar width={80} height={80} publicProfile={profiles[0]} />
            <Spacer horizontal={1} />
            <VStack>
              <TextHeadline as="p">{profileName}</TextHeadline>
              <TextLabel2 as="p">
                {formatMessage(messages.level, {
                level: userState?.levelData?.currentLevel?.level
              }).toUpperCase()}
              </TextLabel2>
            </VStack>
          </Box>
          <HStack justifyContent="space-between">
            {getBadges(userState?.levelData?.currentLevel?.level, isTablet ? 2 : 3)}
          </HStack>
        </HStack>;
    }
    if (isTabletLandscape || isTablet) {
      return <HStack spacingHorizontal={2} justifyContent="space-between">
          <Box>
            <OCSAvatar width={58} height={58} publicProfile={profiles[0]} />
            <Spacer horizontal={1.5} />
            <VStack>
              <TextTitle4 as="p">{profileName}</TextTitle4>
              <TextLegal as="p" className={desktopStatTinyText}>
                {formatMessage(messages.level, {
                level: userState?.levelData?.currentLevel?.level
              }).toUpperCase()}
              </TextLegal>
            </VStack>
          </Box>
          {getBadges(userState?.levelData?.currentLevel?.level, 3)}
        </HStack>;
    }
    if (isMobile) {
      return <VStack spacingHorizontal={2} justifyContent="space-between" gap={2}>
          <Box>
            <OCSAvatar width={80} height={80} publicProfile={profiles[0]} />
            <Spacer horizontal={1} />
            <VStack>
              <TextHeadline as="p">{profileName}</TextHeadline>
              <TextLabel2 as="p">
                {formatMessage(messages.level, {
                level: userState?.levelData?.currentLevel?.level
              }).toUpperCase()}
              </TextLabel2>
            </VStack>
          </Box>
          {spinWheelToggle && <ThemeProvider spectrum="light">
              <Box width="100%" justifyContent="center">
                <Button testID="spinwheelButton" onPress={spinWheelToggle} variant="secondary">
                  <TextHeadline as="p" color="primary">
                    {formatMessage(messages.spinWheelButton)}
                  </TextHeadline>
                </Button>
              </Box>
            </ThemeProvider>}
          <HStack justifyContent="space-between">
            {getBadges(userState?.levelData?.currentLevel?.level, 8)}
          </HStack>
        </VStack>;
    }
    return <HStack spacingHorizontal={2} justifyContent="space-between">
        <Box>
          <OCSAvatar width={80} height={80} publicProfile={profiles[0]} />
          <Spacer horizontal={1} />
          <VStack>
            <TextTitle2 as="p">{profileName}</TextTitle2>
            <TextLabel2 as="p" mono color="secondaryForeground">
              {formatMessage(messages.level, {
              level: userState?.levelData?.currentLevel?.level
            }).toUpperCase()}
            </TextLabel2>
          </VStack>
        </Box>
        {statsDiv}
      </HStack>;
  }, [formatMessage, getBadges, isMobile, isTablet, isTabletLandscape, statsDiv, userState?.levelData, profileName, profiles, spinWheelToggle, condensedView]);
  const summerPassBottom = useMemo(() => {
    const buttonWidth = {
      width: `50%`
    };
    const shareStyle = {
      cursor: `pointer`
    };
    if (condensedView && !isMobile) {
      return <VStack spacingHorizontal={1}>
          <HStack spacingHorizontal={2} justifyContent="space-between">
            {statsDiv}
          </HStack>
          <HStack justifyContent="flex-start" alignItems="center" spacingTop={3} gap={2} spacingHorizontal={1}>
            {spinWheelToggle && <ThemeProvider spectrum="light">
                <Button testID="spinwheelButton" onPress={spinWheelToggle} variant="secondary" compact={!condensedView}>
                  <TextHeadline as="p" color="primary">
                    {formatMessage(messages.spinWheelButton)}
                  </TextHeadline>
                </Button>
              </ThemeProvider>}
            <HStack width="100%" justifyContent="flex-end" gap={2}>
              <Link href="/ocs/faq" passHref>
                <OCSBorderedIcon name="info" size="m" />
              </Link>
              <Box style={shareStyle} onClick={toggleOn}>
                <OCSBorderedIcon name="share" size="m" />
              </Box>
            </HStack>
          </HStack>
        </VStack>;
    }
    if (isMobile) {
      return <VStack spacingHorizontal={1}>
          <HStack spacingHorizontal={2} justifyContent="space-between">
            {statsDiv}
          </HStack>
          <HStack justifyContent="center" spacingTop={2} gap={2} spacingHorizontal={1}>
            <Link href="/ocs/faq" passHref style={buttonWidth}>
              <Button block accessibilityLabel="Information Button" testID="info-icon-faq" variant="primary">
                <HStack>
                  <TextBody mono as="p">
                    {formatMessage(messages.faq)}
                  </TextBody>
                  <Icon spacingStart={2} color="foreground" name="info" size="m" />
                </HStack>
              </Button>
            </Link>
            <Box style={buttonWidth}>{shareButton}</Box>
          </HStack>
        </VStack>;
    }
    if (isTabletLandscape || isTablet) {
      return <HStack spacingHorizontal={2} justifyContent="space-between">
          {statsDiv}
          <HStack>
            <Spacer horizontal={2} />
            <HStack gap={1}>{shareButton}</HStack>
          </HStack>
        </HStack>;
    }
    return <HStack spacingHorizontal={2} justifyContent="space-between">
        {getBadges(userState?.levelData?.currentLevel?.level)}
        <HStack>
          <Spacer horizontal={2} />
          <HStack gap={2}>
            <Link href="/ocs/faq" passHref>
              <Button accessibilityLabel="Information Button" testID="info-icon-faq" variant="primary">
                <HStack>
                  <TextBody mono as="p">
                    {formatMessage(messages.faq)}
                  </TextBody>
                  <Icon spacingStart={2} color="foreground" name="info" size="m" />
                </HStack>
              </Button>
            </Link>
            {shareButton}
          </HStack>
        </HStack>
      </HStack>;
  }, [isMobile, isTabletLandscape, isTablet, statsDiv, getBadges, userState?.levelData?.currentLevel?.level, formatMessage, shareButton, condensedView, toggleOn, spinWheelToggle]);
  const overallGap = useMemo(() => {
    if (isMobile) return 2;
    return undefined;
  }, [isMobile]);
  if (!profiles?.length) {
    const emptyBadges = [];
    for (let i = 0; i < nuxEmptyBadgesLength; i++) {
      emptyBadges.push({
        id: i,
        name: '?'
      });
    }
    const offlineBadges = <Box justifyContent={isDesktop && !condensedView ? 'flex-end' : 'center'} testID="offline-badges">
        <HStack gap={isMobile ? 3 : 4} spacingStart={isDesktop ? 10 : 0}>
          {emptyBadges.map(badge => {
          return <Pressable testID="offline-empty-badge" background="transparent" key={badge.id} onPress={handleNavigateProgress}>
                {handleEmptyBadge(badge)}
              </Pressable>;
        })}
        </HStack>
      </Box>;
    const offlineCardContent = <>
        {condensedView && <Spacer vertical={2} />}
        <Box alignSelf="center" spacingHorizontal={2}>
          <OCSGamePassOfflineLogo />
        </Box>
        <VStack gap={2} maxWidth={580}>
          <TextTitle2 as="h3" align={isMobile || condensedView ? 'center' : 'start'}>
            {formatMessage(messages.offlineTitle)}
          </TextTitle2>
          <TextBody spacingHorizontal={2} as="p" align={isMobile || condensedView ? 'center' : 'start'} dangerouslySetColor="#D2D2D2">
            {formatMessage(messages.offlineSubtitle)}
          </TextBody>
          {isMobile && offlineBadges}
          <Box width="100%" justifyContent={isMobile || condensedView ? 'center' : 'flex-start'} alignSelf={isMobile || condensedView ? 'center' : 'auto'} spacingHorizontal={isMobile || condensedView ? 2 : 0}>
            <div style={{
            width: isDesktop ? '436px' : '100%'
          }}>
              <ThemeProvider palette={paletteOverride}>
                <Button block={isMobile || condensedView} compact variant="primary" onPress={handleOpenModal} testID="getPassBtn">
                  {formatMessage(messages.offlineButton)}
                </Button>
              </ThemeProvider>
            </div>
          </Box>
        </VStack>
        {!(isMobile || condensedView) && offlineBadges}
      </>;
    return (
      // <VStack dangerouslySetBackground="#0052FF" className={summerPass} spacing={isMobile ? 2 : 3}>
      <VStack dangerouslySetBackground="#0052FF" className={summerPass} spacingVertical={2} flexBasis={0} flexGrow={1} flexShrink={1} width="100%" gap={isMobile ? 3 : undefined}>
        <HStack alignItems="center" spacingHorizontal={2} justifyContent={isMobile ? 'space-between' : 'flex-start'} width="100%">
          <Icon name="globe" size="l" spacingEnd={2} color="foreground" />
          <OCSGamePassLogo size={ocsLogoSize} />
        </HStack>
        {isMobile || condensedView ? <VStack gap={3} justifyContent="center" alignItems="center">
            {offlineCardContent}
          </VStack> : <>
            <Spacer vertical={3} />
            <HStack gap={3}>{offlineCardContent}</HStack>
          </>}
      </VStack>
    );
  }
  if (isLoading) {
    return <OCSSummerPassLoading />;
  }
  return <VStack dangerouslySetBackground="#0052FF" className={summerPass} spacingVertical={condensedView && !isMobile ? 4 : 2} flexBasis={0} flexGrow={1} flexShrink={1} width="100%" gap={overallGap} testID="ocs-summer-pass">
      <HStack width="100%" alignItems="center" spacingHorizontal={2} justifyContent="space-between">
        <Box alignItems="center" justifyContent={isMobile ? 'space-between' : 'flex-start'} width="100%">
          <Icon name="globe" size="l" spacingEnd={isMobile ? undefined : 2} color="foreground" />
          <OCSGamePassLogo size={ocsLogoSize} />
        </Box>
        {(isDesktop || isTabletLandscape) && !(condensedView && !isMobile) && spinWheelToggle && <ThemeProvider spectrum="light">
            <Button testID="spinwheelButton" onPress={spinWheelToggle} variant="secondary" compact>
              <TextHeadline as="p" color="primary">
                {formatMessage(messages.spinWheelButton)}
              </TextHeadline>
            </Button>
          </ThemeProvider>}
      </HStack>
      {!overallGap && <Spacer vertical={4} />}
      {summerPassTop}
      {!overallGap && <Spacer vertical={4} />}
      <Box height={1} dangerouslySetBackground="white" />
      {!overallGap && <Spacer vertical={4} />}

      {summerPassBottom}
      {summerPassShareModal}
    </VStack>;
}
const passLoadingBadgeGap = {
  rowGap: '5px'
};
export function OCSSummerPassLoading() {
  const isMobile = useIsMobile();
  const ocsLogoSize = useMemo(() => {
    if (isMobile) return 's';
    return 'm';
  }, [isMobile]);
  const overallGap = useMemo(() => {
    if (isMobile) return 2;
    return 3;
  }, [isMobile]);
  const horizontalGap = useMemo(() => {
    if (isMobile) return 1.5;
    return 2;
  }, [isMobile]);
  const loadingBadge = useCallback((index: number) => {
    const loadingBadgeSize = isMobile ? 25 : 40;
    const badgeTextSize = isMobile ? 7 : 14;
    const spacerVertical: SpacingScale = isMobile ? 0 : 0.5;
    return <VStack alignItems="center" key={`loading_badge_${index}`}>
          <Box width={loadingBadgeSize} height={loadingBadgeSize} borderRadius="roundedXLarge" dangerouslySetBackground="white" />
          <Spacer vertical={spacerVertical} />
          <Box width={badgeTextSize} height={badgeTextSize} dangerouslySetBackground="white" />
        </VStack>;
  }, [isMobile]);
  const statsDiv = useMemo(() => {
    const tinyTextSizeH = isMobile ? 7 : 14;
    const tinyTextSizeW = isMobile ? 20 : 50;
    const mediumTextSizeH = isMobile ? 20 : 28;
    const mediumTextSizeW = isMobile ? 30 : 75;
    const pinkCircleSize = isMobile ? 6 : 12;
    const stackGapSize: SpacingScale = isMobile ? 1 : 1.5;
    return <HStack gap={1} justifyContent="space-between" className={shareableHStackFull}>
        <VStack gap={stackGapSize}>
          <Box alignItems="center">
            <Box width={pinkCircleSize} height={pinkCircleSize} borderRadius="roundedXLarge" dangerouslySetBackground="white" />
            <Spacer horizontal={0.5} />
            <Box width={tinyTextSizeW} height={tinyTextSizeH} dangerouslySetBackground="white" />
          </Box>
          <Box width={mediumTextSizeW} height={mediumTextSizeH} dangerouslySetBackground="white" />
        </VStack>
        <VStack gap={stackGapSize}>
          <Box alignItems="center">
            <Box width={pinkCircleSize} height={pinkCircleSize} borderRadius="roundedXLarge" dangerouslySetBackground="white" />
            <Spacer horizontal={0.5} />
            <Box width={tinyTextSizeW} height={tinyTextSizeH} dangerouslySetBackground="white" />
          </Box>
          <Box width={mediumTextSizeW} height={mediumTextSizeH} dangerouslySetBackground="white" />
        </VStack>
        <VStack gap={stackGapSize}>
          <Box alignItems="center">
            <Box width={pinkCircleSize} height={pinkCircleSize} borderRadius="roundedXLarge" dangerouslySetBackground="white" />
            <Spacer horizontal={0.5} />
            <Box width={tinyTextSizeW} height={tinyTextSizeH} dangerouslySetBackground="white" />
          </Box>
          <Box width={mediumTextSizeW} height={mediumTextSizeH} dangerouslySetBackground="white" />
        </VStack>
      </HStack>;
  }, [isMobile]);
  return <VStack dangerouslySetBackground="#0052FF" className={summerPass} spacingVertical={2} flexBasis={0} flexGrow={1} flexShrink={1} gap={overallGap} testID="ocs-summer-pass-loading">
      <HStack width="100%" alignItems="center" spacingHorizontal={2} justifyContent="space-between">
        <Box alignItems="center" justifyContent={isMobile ? 'space-between' : 'flex-start'} width="100%">
          <Icon name="globe" size={isMobile ? 's' : 'l'} spacingEnd={2} color="foreground" />
          <OCSGamePassLogo size={ocsLogoSize} />
        </Box>
      </HStack>
      <HStack spacingHorizontal={2} justifyContent="space-between">
        <Box>
          <Box width={isMobile ? 35 : 58} height={isMobile ? 35 : 58} dangerouslySetBackground="white" borderRadius="rounded" />
          <Spacer horizontal={isMobile ? 1 : 1.5} />
          <VStack>
            <Box dangerouslySetBackground="white" width={isMobile ? 50 : 121} height={isMobile ? 14 : 24} />
            <Box dangerouslySetBackground="white" width={isMobile ? 30 : 63} height={isMobile ? 7 : 15} />
          </VStack>
        </Box>
        <HStack gap={horizontalGap} flexWrap="wrap" justifyContent="center" style={passLoadingBadgeGap}>
          {[...Array(3)].map((_, index) => loadingBadge(index))}
        </HStack>
      </HStack>
      <Box height={1} dangerouslySetBackground="white" />
      <HStack spacingHorizontal={2} justifyContent="space-between">
        <HStack spacingHorizontal={2} justifyContent="space-between" className={shareableHStackFull}>
          {statsDiv}
        </HStack>
      </HStack>
    </VStack>;
}

require("./OCSSummerPass.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./OCSSummerPass.tsx");