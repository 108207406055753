import { memo, useCallback, useMemo } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useGetUserState } from 'cb-wallet-data/hooks/Gamification/useUserOCSProfile';
import { VStack } from '@cbhq/cds-web/layout';

import { CardWithImage } from ':dapp/components/Card/CardWithImage';
import {
  CarouselArrows,
  CarouselContainer,
  CarouselProvider,
} from ':dapp/components/Carousel/Carousel';
import { useIsMobile } from ':dapp/hooks/useIsMobile';
import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';

import { useClaimCard } from '../hooks/useClaimBadgeModal';

import { badgeCards, messages } from './badgeContent';
import { OCSSectionHeader } from './OCSSectionHeader';

export type BadgeCardType = {
  id: string;
  title: MessageDescriptor;
  imageUrl: string;
  targetUrl: string;
  shortDescription: MessageDescriptor;
  errorMessage: MessageDescriptor;
  ctaText: MessageDescriptor;
  value?: string;
};

export const testID = 'ocs-earn-badges-section';

export function OCSEarnBadgesSection() {
  const { earnBadgeModal, buttonHandler } = useClaimCard();
  const isMobile = useIsMobile();

  const { account } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS,
  });

  const { userState } = useGetUserState(account?.primaryAddressForDapp);

  const availableBadges = badgeCards.filter((badge) => {
    return !userState?.badges?.find((userBadge) => userBadge.id.toString() === badge.id);
  });

  const handleClick = useCallback(
    (content: BadgeCardType) => () => {
      buttonHandler(content);
    },
    [buttonHandler],
  );

  if (availableBadges.length === 0) {
    return null;
  }

  return (
    <VStack testID={testID}>
      {earnBadgeModal}
      <CarouselProvider>
        <OCSSectionHeader
          title="Earn badges"
          subtitle="Check your eligibility for exclusive badges. Badges will display on your Summer Pass."
          spacingTop={4}
          spacingBottom={isMobile ? 3 : 4}
          overrideMobile={isMobile}
          transpose={isMobile}
          rightContent={<CarouselArrows />}
        />

        <CarouselContainer>
          {availableBadges.map((card) => (
            <BadgeCard
              card={card}
              key={`trending-${card.id}`}
              onClick={handleClick({ ...card, id: card.id })}
            />
          ))}
        </CarouselContainer>
      </CarouselProvider>
    </VStack>
  );
}

type BadgeCardProps = {
  card: BadgeCardType;
  onClick: () => void;
};

const BadgeCard = memo(({ card, onClick }: BadgeCardProps) => {
  const { formatMessage } = useIntl();

  const image = useMemo(() => ({ src: card.imageUrl }), [card]);
  const action = useMemo(
    () => ({ url: card.targetUrl, text: formatMessage(messages.badgeClaimCtaText) }),
    [card.targetUrl, formatMessage],
  );

  const title = useMemo(() => {
    return card.value
      ? formatMessage(card.title, { count: card.value })
      : formatMessage(card.title);
  }, [card.value, card.title, formatMessage]);

  const shortDescription = useMemo(() => {
    return card.value
      ? formatMessage(card.shortDescription, { count: card.value })
      : formatMessage(card.shortDescription);
  }, [card.value, card.shortDescription, formatMessage]);

  return (
    <CardWithImage
      key={`ocs-play-tab-cards-${card.title}`}
      title={title}
      image={image}
      onClick={onClick}
      text={shortDescription}
      action={action}
    />
  );
});
