export const testID = 'ocs-gamepass-logo';

export function OCSGamePassOfflineLogo() {
  return (
    <svg
      width="240"
      height="121"
      viewBox="0 0 240 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="OCSGamePassOfflineLogo"
    >
      <path
        d="M208.027 0.831299H0V104.745H208.027V0.831299Z"
        fill="url(#paint0_linear_9866_28468)"
      />
      <path d="M240 16.918H32.1731V120.831H240V16.918Z" fill="url(#paint1_linear_9866_28468)" />
      <path d="M208.027 16.918H32.1731V104.845H208.027V16.918Z" fill="#0052FF" />
      <path
        d="M76.0367 88.7579C91.4326 88.7579 103.913 76.2771 103.913 60.8812C103.913 45.4852 91.4326 33.0044 76.0367 33.0044C60.6408 33.0044 48.1599 45.4852 48.1599 60.8812C48.1599 76.2771 60.6408 88.7579 76.0367 88.7579Z"
        fill="black"
      />
      <path
        d="M132.29 64.778C125.196 64.778 120 59.8821 120 52.9879C120 46.2935 124.996 40.8979 132.49 40.8979C139.184 40.8979 143.98 45.4941 143.98 51.8888C143.98 56.385 141.682 58.9829 138.485 58.9829C136.586 58.9829 135.187 58.0836 134.788 56.5849C134.188 58.0836 132.789 59.0828 130.791 59.0828C128.193 59.0828 126.395 57.684 126.395 55.4858C126.395 53.1877 128.193 52.0886 131.291 51.7889L132.59 51.689C133.589 51.589 133.988 51.1894 133.988 50.5899C133.988 49.8905 133.489 49.291 132.29 49.291C130.991 49.291 130.391 49.7905 130.391 50.6898H126.794C127.094 48.3917 128.993 46.793 132.29 46.793C135.087 46.793 137.585 47.8921 137.585 51.2893V55.186C137.585 56.0853 138.085 56.5849 138.784 56.5849C140.183 56.5849 140.983 54.4866 140.983 52.0886C140.983 47.0928 137.386 43.8955 132.49 43.8955C126.994 43.8955 123.197 47.7922 123.197 53.1877C123.197 58.6831 127.194 62.1802 132.39 62.1802C134.488 62.1802 135.987 61.7805 137.286 61.281V64.0786C135.887 64.4783 134.288 64.778 132.29 64.778ZM133.988 53.3875C133.789 53.5874 133.289 53.7872 132.789 53.7872L131.89 53.8871C130.691 53.987 130.092 54.4866 130.092 55.186V55.286C130.092 55.8855 130.591 56.385 131.69 56.385C133.289 56.385 134.088 55.3859 134.088 53.8871V53.3875H133.988Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M192.04 74.0701H120.2V71.6721H192.04V74.0701Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M191.94 82.0635H120.2V79.6655H191.94V82.0635Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.657 90.0569H120.2V87.6589H171.657V90.0569Z"
        fill="black"
      />
      <path
        d="M192.04 48.8911H152.073V56.8844H192.04V48.8911Z"
        fill="url(#paint2_linear_9866_28468)"
      />
      <path d="M208.027 16.918H32.1731V24.9113H208.027V16.918Z" fill="black" />
      <path
        d="M76.0272 44.1562C80.6992 44.1562 84.5016 47.9473 84.5016 52.6303V55.8663L86.5607 55.8966L86.6945 53.4994C86.7837 51.8268 88.2333 50.5446 89.8947 50.6226C91.5673 50.7118 92.8607 52.139 92.7715 53.8227L92.582 57.3127C92.4816 59.8884 90.2961 61.9957 87.6757 61.9957H87.5754L84.5016 61.9476V71.4518H67.5528V61.9599L64.4948 62.0078H64.4167L64.3944 61.9966C61.7852 61.9966 59.5886 59.8892 59.4882 57.269L59.3098 53.8347C59.2206 52.1622 60.5029 50.7238 62.1866 50.6346C63.8592 50.5343 65.2976 51.8277 65.3868 53.5114L65.5206 55.9087L67.5528 55.8786V52.6192C67.5528 47.9473 71.3551 44.1562 76.0272 44.1562Z"
        fill="url(#paint3_linear_9866_28468)"
      />
      <path d="M92.7487 66.4343H59.2971V71.0505H92.7487V66.4343Z" fill="#FFE835" />
      <path
        d="M92.7487 71.0503H59.2971L68.2956 77.6066H83.7614L92.7487 71.0503Z"
        fill="url(#paint4_linear_9866_28468)"
      />
      <path
        d="M72.7476 56.8898C73.6837 56.8898 74.4425 55.6518 74.4425 54.1246C74.4425 52.5974 73.6837 51.3594 72.7476 51.3594C71.8116 51.3594 71.0527 52.5974 71.0527 54.1246C71.0527 55.6518 71.8116 56.8898 72.7476 56.8898Z"
        fill="black"
      />
      <path
        d="M79.3162 56.8898C80.2523 56.8898 81.0111 55.6518 81.0111 54.1246C81.0111 52.5974 80.2523 51.3594 79.3162 51.3594C78.3802 51.3594 77.6213 52.5974 77.6213 54.1246C77.6213 55.6518 78.3802 56.8898 79.3162 56.8898Z"
        fill="black"
      />
      <path
        d="M79.7712 58.8525C79.7712 60.8707 78.1321 62.4986 76.125 62.4986C74.1179 62.4986 72.4788 60.8596 72.4788 58.8525H79.7712Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9866_28468"
          x1="1.35618e-06"
          y1="52.788"
          x2="208.027"
          y2="52.788"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B139FF" />
          <stop offset="1" stopColor="#C872FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9866_28468"
          x1="50.245"
          y1="120.831"
          x2="240"
          y2="120.831"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.170635" stopColor="#F8BDF5" />
          <stop offset="0.72619" stopColor="#7FD057" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9866_28468"
          x1="153.295"
          y1="56.4671"
          x2="181.364"
          y2="35.4713"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.263767" stopColor="#FFD200" />
          <stop offset="0.602385" stopColor="#EBCEF8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9866_28468"
          x1="72.0703"
          y1="56.4928"
          x2="78.8182"
          y2="53.4346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7FD057" />
          <stop offset="1" stopColor="#45E1E5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9866_28468"
          x1="59.2971"
          y1="74.3284"
          x2="92.7487"
          y2="74.3284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CE46BD" />
          <stop offset="1" stopColor="#DF64D2" />
        </linearGradient>
      </defs>
    </svg>
  );
}
