import { ImageProps } from 'react-custom-roulette/dist/components/Wheel/types';
import { SpinOption, SpinOptionTypeEnum } from 'cb-wallet-data/hooks/Gamification/useSpinWheel';

/**
 * Determines the prize image based on the spin option.
 *
 * @param spinOption - The spin option to determine the prize image for.
 * @returns The ImageProps for the prize image, or undefined if no match is found.
 */
export function determinePrizeImage(spinOption: SpinOption): ImageProps | undefined {
  const spinOptionType: SpinOptionTypeEnum | undefined = spinOption?.type;
  const spinOptionPoints: number | undefined = spinOption?.points;

  if (!spinOptionType || spinOptionPoints === undefined) {
    return undefined;
  }
  if (spinOptionType === SpinOptionTypeEnum?.USDC) {
    switch (spinOptionPoints) {
      case 5:
        return {
          uri: 'https://go.wallet.coinbase.com/static/5-usdc.png',
          sizeMultiplier: 0.28,
          landscape: true,
          offsetX: 50,
        };
      case 10:
        return {
          uri: 'https://go.wallet.coinbase.com/static/10-usdc.png',
          sizeMultiplier: 0.28,
          landscape: true,
          offsetX: 30,
        };
      case 100:
        return {
          uri: 'https://go.wallet.coinbase.com/static/100-usdc.png',
          sizeMultiplier: 0.28,
          landscape: true,
          offsetX: 10,
        };
      default:
        return undefined;
    }
  }
  return undefined;
}

/**
 * Gets the index of the spin option with the specified ID from the spin state.
 *
 * @param res - The result state containing the last spin result ID.
 * @param spinState - The current spin state containing the spin options.
 * @returns The index of the spin option with the specified ID
 * @throws An error if the ID is not found in the spin options.
 * @throws An error if the lastSpinResultId or spinOptions is undefined.
 *
 */
export function getIndexWithId(
  lastSpinResultId: number,
  spinOptions: SpinOption[] | undefined,
): number {
  if (lastSpinResultId && spinOptions) {
    const index = spinOptions.findIndex((option) => option.id === lastSpinResultId);
    if (index !== -1) {
      return index;
    }
    throw new Error(`id: ${lastSpinResultId} not found in spinOptions`);
  }
  throw new Error(`lastSpinResultId or spinOptions is undefined`);
}
