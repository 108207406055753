import { useCallback, useMemo } from 'react';
import { useGetGameLeaderboard } from 'cb-wallet-data/hooks/Gamification/useGetGameLeaderboard';
import { usePublicProfileByAddresses } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddresses';
import { PublicProfile } from 'cb-wallet-data/stores/DecentralizedID/types';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import { Spacer, VStack } from '@cbhq/cds-web/layout';

import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';

import { LeaderboardSection } from './Leaderboard';
import { YourScoreSection } from './YourScore';

export function OCSLeaderboard() {
  const { setGamificationApiIssue } = usePageOutageContext();
  const { leaderboard, isLoading } = useGetGameLeaderboard(1, 10, setGamificationApiIssue);

  const { account } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS,
  });

  const { profiles } = usePublicProfileByAddresses({
    addresses: leaderboard?.length
      ? [
          ...leaderboard.map((user) => user.userAddress),
          ...(account ? [account.primaryAddress] : []),
        ]
      : [],
  });

  const userProfiles = useMemo(() => {
    const profileObj: Record<string, PublicProfile> = {};
    if (!profiles) return profileObj;

    profiles?.forEach((profile) => {
      profileObj[profile.address] = profile;
    });

    return profileObj;
  }, [profiles]);

  const getUserProfileName = useCallback(
    (userAddress: string) => {
      return userProfiles[userAddress]?.primaryDomainType === 'eth'
        ? userProfiles[userAddress]?.ensDomainProfile?.name
        : userProfiles[userAddress]?.subdomainProfile?.name;
    },
    [userProfiles],
  );

  return (
    <VStack gap={1} spacingVertical={2}>
      <YourScoreSection
        account={account}
        getUserProfileName={getUserProfileName}
        userProfiles={userProfiles}
      />
      <Spacer vertical={2} />
      <LeaderboardSection
        leaderboard={leaderboard}
        getUserProfileName={getUserProfileName}
        userProfiles={userProfiles}
        isLoading={isLoading}
      />
    </VStack>
  );
}
