import { createContext, RefObject, useContext, useMemo, useRef } from 'react';

export type CarouselContextValue = {
  prev?: () => void;
  next?: () => void;
  carouselRef?: RefObject<HTMLElement>;
};

export const CarouselContext = createContext<CarouselContextValue>({});

type CarouselProviderProps = {
  children: React.ReactNode;
};

export function CarouselProvider({ children }: CarouselProviderProps) {
  const carouselRef = useRef<HTMLElement>(null);

  const value = useMemo(
    () => ({
      prev: () => {
        carouselRef.current?.scrollBy({
          left: -carouselRef.current.clientWidth,
          top: 0,
          behavior: 'smooth',
        });
      },
      next: () => {
        carouselRef.current?.scrollBy({
          left: carouselRef.current.clientWidth,
          top: 0,
          behavior: 'smooth',
        });
      },
      carouselRef,
    }),
    [],
  );

  return <CarouselContext.Provider value={value}>{children}</CarouselContext.Provider>;
}

export function useCarousel() {
  const context = useContext(CarouselContext);

  return context;
}
