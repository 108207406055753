import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { getJSON } from 'cb-wallet-http/fetchJSON';

import type { PublicProfile } from '../types';

export const ENDPOINT = 'getPublicProfilesByAddresses';

export type ProfilesError = {
  code: string;
  message: string;
  description: string;
};

type Params = {
  addresses: string[];
};

type PublicProfileResponse = { result: { profiles: PublicProfile[] } };

const queryLimit = 50;

/**
 * @param addresses - ETH addresses
 * @returns A list of Public Profiles
 */
export async function fetchPublicProfileByAddresses(addresses: string[]): Promise<PublicProfile[]> {
  if (addresses.length === 0) {
    return [];
  }

  const requests = [];
  for (let i = 0; i < addresses.length; i += queryLimit) {
    const chunk = addresses.slice(i, i + queryLimit);
    requests.push(getJSON<PublicProfileResponse>(ENDPOINT, { queryAddresses: chunk.join(',') }));
  }

  const results = await Promise.allSettled(requests);
  const profiles = results
    .filter(({ status }) => status === 'fulfilled')
    .flatMap(
      (result) => (result as PromiseFulfilledResult<PublicProfileResponse>).value?.result?.profiles,
    );

  return profiles;
}

export function usePublicProfileByAddresses({ addresses }: Params): {
  profiles: PublicProfile[];
  isLoading: boolean;
  error: ProfilesError;
} {
  const { data, error, isInitialLoading } = useQuery(
    [ENDPOINT, addresses],
    async () => fetchPublicProfileByAddresses(addresses),
    {
      enabled: addresses.length > 0,
      suspense: false,
      staleTime: 1000 * 30,
      notifyOnChangeProps: ['data', 'error'],
    },
  );

  return { profiles: data ?? [], isLoading: isInitialLoading, error: error as ProfilesError };
}
