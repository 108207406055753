import { defineMessages, useIntl } from 'react-intl';
import { PlayerRank } from 'cb-wallet-data/hooks/Gamification/useGetUserRank';
import { PublicProfile } from 'cb-wallet-data/stores/DecentralizedID/types';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';

import { useIsMobile } from ':dapp/hooks/useIsMobile';

import { OCSLeaderboardItem } from './OCSLeaderboardItem';

const messages = defineMessages({
  tableHeading: {
    defaultMessage: 'players',
    description: 'subheading denoting players in the table',
  },
  tablePoints: {
    defaultMessage: 'total points',
    description: 'subheading denoting total points in the table',
  },
});

type LeaderboardSectionProps = {
  leaderboard: PlayerRank[] | undefined;
  getUserProfileName: (userAddress: string) => string | undefined;
  userProfiles: Record<string, PublicProfile>;
  isLoading: boolean;
};

export function LeaderboardSection({
  getUserProfileName,
  isLoading,
  leaderboard,
  userProfiles,
}: LeaderboardSectionProps) {
  const { formatMessage } = useIntl();
  const isMobile = useIsMobile();

  return (
    <>
      <HStack justifyContent="space-between">
        <TextBody as="p"> {formatMessage(messages.tableHeading).toUpperCase()}</TextBody>
        {isMobile ? null : (
          <TextBody as="p"> {formatMessage(messages.tablePoints).toUpperCase()}</TextBody>
        )}
      </HStack>
      <VStack gap={3} spacingVertical={1} />
      <Leaderboard
        leaderboard={leaderboard}
        isLoading={isLoading}
        getUserProfileName={getUserProfileName}
        userProfiles={userProfiles}
      />
    </>
  );
}

type LeaderboardProps = {
  leaderboard?: PlayerRank[];
  isLoading: boolean;
  userProfiles: Record<string, PublicProfile>;
  getUserProfileName: (userAddress: string) => string | undefined;
};

function Leaderboard({
  leaderboard,
  isLoading,
  getUserProfileName,
  userProfiles,
}: LeaderboardProps) {
  if (isLoading) {
    return (
      <>
        {[...Array(10)].map((_, index) => (
          <Box
            width="100%"
            height={82}
            background="secondary"
            borderRadius="roundedXLarge"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        ))}
      </>
    );
  }

  return (
    <>
      {leaderboard?.map((user, index) => (
        <OCSLeaderboardItem
          key={user.userAddress}
          rank={index + 1}
          name={getUserProfileName(user.userAddress)}
          points={user.currentScore}
          userAddress={user.userAddress}
          inverse={false}
          userProfile={userProfiles[user.userAddress]}
        />
      ))}
    </>
  );
}
