import { useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useMutateBadgeClaim } from 'cb-wallet-data/hooks/Gamification/useMutateBadgeClaim';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import Image from 'next/image';
import { Button } from 'wallet-cds-web/components/Button';
import { useToggler } from '@cbhq/cds-common';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Spinner } from '@cbhq/cds-web/loaders/Spinner';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { TextLabel2 } from '@cbhq/cds-web/typography';

import { imageClass } from ':dapp/components/Card/CardWithImage';
import { HomebaseModal } from ':dapp/components/HomebaseModal/HomebaseModal';
import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';

import { BadgeCardType } from '../components/OCSEarnBadgesSection';

export const testID = 'claimBadgeModal';

export const messages = defineMessages({
  claimedMessage: {
    defaultMessage: 'Congrats! Badge will show up on your summer pass in a bit.',
    description: 'Badge Claimed message',
  },
  claimedConfirmed: {
    defaultMessage: 'Got it',
    description: 'confirm message for claiming badge',
  },
  claimedError: {
    defaultMessage: 'Something went wrong. Please try again later.',
    description: 'error message if claim fails',
  },
});

type OCSSBadgeModalProps = {
  cardDetails: BadgeCardType;
  toggleOff: () => void;
};

function OCSClaimBadgeModal({ cardDetails, toggleOff }: OCSSBadgeModalProps) {
  const { formatMessage } = useIntl();
  const [claimError, setClaimError] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { account } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS,
  });

  const { setGamificationApiIssue } = usePageOutageContext();
  const {
    claimBadge,
    isLoading: claimBadgeLoading,
    data: badgeData,
  } = useMutateBadgeClaim(setGamificationApiIssue);

  /* istanbul ignore next @preserve */
  const invalidateProfileState = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['game_userProfileState'],
    });
  }, [queryClient]);

  const handleClaimBadge = useCallback(() => {
    if (!account?.primaryAddressForDapp) return;

    try {
      claimBadge(
        {
          walletAddress: account?.primaryAddressForDapp,
          badgeId: cardDetails.id,
        },
        {
          // refreshes summer pass and badge section
          /* istanbul ignore next @preserve */
          onSuccess: invalidateProfileState,
        },
      );
    } catch (err) {
      setClaimError(true);

      cbReportError({
        error: coerceError(err, `error thrown when claiming badge ${cardDetails.id}`),
        context: 'dapp-ocs-error',
        severity: 'error',
        isHandled: false,
      });
    }
  }, [account?.primaryAddressForDapp, claimBadge, cardDetails.id, invalidateProfileState]);

  useEffect(handleClaimBadge, [handleClaimBadge]);

  const userMessage = useMemo(() => {
    if (claimError) {
      // display unable to claim message due to network error
      return formatMessage(messages.claimedError);
    }

    if (badgeData?.success) {
      return formatMessage(messages.claimedMessage, {
        title: cardDetails.title.defaultMessage as string,
      });
    }

    // display failed to claim message
    return cardDetails.value
      ? formatMessage(cardDetails.errorMessage, { count: cardDetails.value })
      : formatMessage(cardDetails.errorMessage);
  }, [
    claimError,
    badgeData?.success,
    cardDetails.value,
    cardDetails.errorMessage,
    cardDetails.title,
    formatMessage,
  ]);

  const ctaButton = useMemo(() => {
    return badgeData?.success ? (
      <Button testID="got-it-btn" onPress={toggleOff} variant="secondary" block compact>
        {formatMessage(messages.claimedConfirmed)}
      </Button>
    ) : (
      <Button
        testID="claim-btn"
        href={cardDetails.targetUrl}
        target="_blank"
        rel="noreferrer"
        variant="secondary"
        block
        compact
      >
        {formatMessage(cardDetails.ctaText)}
      </Button>
    );
  }, [badgeData?.success, cardDetails.ctaText, formatMessage, toggleOff, cardDetails.targetUrl]);

  return (
    <VStack testID={testID} width="100%">
      <HStack justifyContent="center">
        <Image
          src={cardDetails.imageUrl}
          alt={formatMessage(cardDetails.title)}
          width={410}
          height={200}
          className={imageClass}
        />
      </HStack>
      {claimBadgeLoading ? (
        <HStack justifyContent="center" spacingVertical={3}>
          <Spinner size={3} />
        </HStack>
      ) : (
        <>
          <TextLabel2
            as="p"
            color="foregroundMuted"
            spacingHorizontal={1}
            overflow="wrap"
            spacingVertical={5}
          >
            {userMessage}
          </TextLabel2>
          <ThemeProvider spectrum="light">{ctaButton}</ThemeProvider>
        </>
      )}
    </VStack>
  );
}

export function useClaimCard() {
  const [visible, { toggleOff, toggleOn }] = useToggler(false);
  const [cardData, setCardData] = useState<BadgeCardType>();

  const earnBadgeModal = useMemo(() => {
    if (!visible || !cardData) return null;
    return (
      <HomebaseModal
        title="Claim badge"
        visible={visible}
        onClose={toggleOff}
        testID="earn-badge-modal"
        contentSpacingBottom={4}
      >
        <OCSClaimBadgeModal cardDetails={cardData} toggleOff={toggleOff} />
      </HomebaseModal>
    );
  }, [visible, toggleOff, cardData]);

  const buttonHandler = useCallback(
    (newContent: BadgeCardType) => {
      setCardData(newContent);
      toggleOn();
    },
    [toggleOn],
  );

  return { buttonHandler, earnBadgeModal };
}
