import { defineMessages, useIntl } from 'react-intl';
import { useNftAvatarMetadata } from 'cb-wallet-data/stores/Collection/hooks/useNftAvatarMetadata';
import { usePublicProfileByAddress } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddress';
import { useDefaultAvatarIdxByAddress } from 'cb-wallet-data/stores/WalletGroups/hooks/useDefaultAvatarIdxByAddress';
import { truncateMiddle } from 'cb-wallet-data/utils/String+Core';
import { CollectibleMedia } from 'wallet-cds-web/components/CollectibleMedia';
import { useProfileDefaultAvatar } from 'wallet-cds-web/components/ProfileAvatar/DefaultAvatar';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { RemoteImage } from '@cbhq/cds-web/media';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';
export const testID = 'ocs-referrer-card';
const messages = defineMessages({
  referral: {
    defaultMessage: 'referral',
    description: 'Single referral label'
  },
  referrals: {
    defaultMessage: 'referrals',
    description: 'Plural referral label'
  }
});
export const colors = ['blue', 'green', 'pink', 'orange', 'purple'] as const;
const gradientClasses: { [key in (typeof colors)[number]]: string } = {
  blue: "b1fy18u4",
  green: "g1b2kh0e",
  pink: "p7i5qtt",
  orange: "omovlrj",
  purple: "pa1tgkw"
};
const linkClass = "l1ndg6ee";
const rankClass = "r193xjno";
function getRandomGradientClass() {
  const randomColorIndex = colors[Math.floor(Math.random() * (colors.length + 1))];
  return gradientClasses[randomColorIndex];
}
type ReferrerCardProps = {
  address: string;
  referrals: number;
  rank: number;
  colorScheme?: (typeof colors)[number];
};
export function ReferrerCard({
  address,
  referrals,
  rank,
  colorScheme
}: ReferrerCardProps) {
  const {
    formatMessage
  } = useIntl();
  const profile = usePublicProfileByAddress({
    address
  });
  const avatarSource = profile.publicProfile?.primaryDomainType === 'eth' ? profile.publicProfile?.ensDomainProfile?.profileTextRecords.avatar : profile.publicProfile?.subdomainProfile?.profileTextRecords.avatar;
  const profileName = profile.publicProfile?.primaryDomainType === 'eth' ? profile.publicProfile?.ensDomainProfile?.name : profile.publicProfile?.subdomainProfile?.name;
  const nftAvatarMetadata = useNftAvatarMetadata(avatarSource);
  const avatarIdx = useDefaultAvatarIdxByAddress(address);
  const defaultAvatar = useProfileDefaultAvatar(avatarIdx);
  const title = profileName ?? truncateMiddle(address, 4, 4);
  const referralsLabel = referrals === 1 ? formatMessage(messages.referral) : formatMessage(messages.referrals);
  const gradientClass = colorScheme ? gradientClasses[colorScheme] : getRandomGradientClass();
  return <Box className={linkClass}>
      <VStack testID={testID} alignItems="center">
        <Box spacing={1} className={gradientClass} borderRadius="roundedXLarge">
          <Box borderRadius="roundedLarge" overflow="hidden" minHeight={200} minWidth={200} width="100%">
            {nftAvatarMetadata ? <CollectibleMedia chainId={nftAvatarMetadata.chainId} width="200px" height="200px" {...nftAvatarMetadata.collectible} /> : <RemoteImage alt={`${title} Thumbnail`} source={defaultAvatar} shape="squircle" resizeMode="cover" width="100%" height="100%" />}
          </Box>
        </Box>

        <VStack gap={3} alignItems="center">
          <Box spacingHorizontal={4} spacingVertical={1.5} background="backgroundAlternate" className={rankClass} borderRadius="roundedFull" zIndex={1}>
            <TextHeadline as="span" align="center" color="currentColor">{`#${rank}`}</TextHeadline>
          </Box>

          <VStack>
            <ThemeProvider scale="xxLarge">
              <TextHeadline as="h3" align="center">
                {title}
              </TextHeadline>
            </ThemeProvider>

            <TextBody as="p" align="center" color="foregroundMuted">
              {referrals} {referralsLabel}
            </TextBody>
          </VStack>
        </VStack>
      </VStack>
    </Box>;
}

require("./ReferrerCard.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./ReferrerCard.tsx");